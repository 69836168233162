import React from 'react';
import Select from 'react-select';

import './Dropdown.scss';

const options = [
  { value: 'create', label: '作成日時' },
  { value: 'like', label: 'いいね' },
  { value: 'comment', label: 'コメント' },
];

const dropdown = (props) => (
  <Select
    placeholder={props.children}
    className="react-select-container"
    classNamePrefix="react-select"
    options={options}
    onChange={props.sortClick}
  />
);

export default dropdown;
