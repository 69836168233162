import React from "react";
import ImageGallery from "react-image-gallery";
import { config } from "./../../config";

import "react-image-gallery/styles/scss/image-gallery.scss";
import "./Slider.scss";

const imageSlider = (props) => {
  const images = props.placeImages.map((image) => {
    return {
      original: `${config.s3.URL}/places/${image}`,
    };
  });

  return (
    <ImageGallery
      showThumbnails={false}
      showFullscreenButton={false}
      showPlayButton={false}
      showBullets={true}
      items={images}
    />
  );
};

export default imageSlider;
