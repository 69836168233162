import React from "react";

import { SpinnerCircularFixed } from "spinners-react";

import "./Spinner.scss";

const spinner = () => (
  <div className="Spinner">
    <SpinnerCircularFixed
      size={50}
      thickness={100}
      speed={100}
      color="rgba(57, 118, 172, 1)"
      secondaryColor="rgba(0, 0, 0, 0.44)"
    />
  </div>
);

export default spinner;
