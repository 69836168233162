import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { withAlert } from "react-alert";

import Input from "../../components/UI/Input/Input";
import Button from "../../components/UI/Button/Button";

import { inputChangedHandlerFunc } from "./../../utility/InputChangeHandler/InputChangeHandler";
import { isObjEmpty } from "./../../utility/Share/utilFunc";

import classes from "./Signup.module.scss";

import * as actionCreators from "./../../store/actions/index";

class Signup extends Component {
  state = {
    controls: {
      name: {
        elementType: "input",
        label: "ユーザー名",
        elementConfig: {
          type: "text",
          placeholder: "ユーザー名",
          id: "name",
          minLength: 4,
        },
        value: "",
        validation: {
          required: true,
        },
      },
      password: {
        elementType: "input",
        label: "パスワード",
        elementConfig: {
          type: "password",
          placeholder: "••••••••",
          id: "password",
          minLength: 8,
        },
        value: "",
        validation: {
          required: true,
        },
      },
      passwordConfirm: {
        elementType: "input",
        label: "パスワードの確認",
        elementConfig: {
          type: "password",
          placeholder: "••••••••",
          id: "passwordConfirm",
          minLength: 8,
        },
        value: "",
        validation: {
          required: true,
        },
      },
    },
    isSignup: true,
  };

  inputChangedHandler = (event, controlName) => {
    this.setState({
      controls: inputChangedHandlerFunc(this.state, event, controlName),
    });
  };

  submitHandler = async (event) => {
    event.preventDefault();
    const alert = this.props.alert;

    try {
      const { name, password, passwordConfirm } = this.state.controls;

      await this.props.onSignupAuth(
        name.value,
        password.value,
        passwordConfirm.value
      );

      if (!isObjEmpty(this.props.user)) {
        // 成功のメッセージ
        alert.success("アカウントの登録に成功しました");
        this.props.history.push("/");
      }

      if (this.props.message.login !== null) {
        // 失敗のメッセージ
        alert.error(`${this.props.message.login}`);

        this.props.onResetErrorMsg();
      }
    } catch (error) {
      // 失敗のメッセージ
      alert.error("アカウントの登録に失敗しました");
    }
  };

  render() {
    const formElArray = [];
    for (const key in this.state.controls) {
      formElArray.push({
        id: key,
        config: this.state.controls[key],
      });
    }
    let form = formElArray.map((el) => (
      <Input
        key={el.id}
        elementType={el.config.elementType}
        label={el.config.label}
        htmlFor={el.config.elementConfig.id}
        elementConfig={el.config.elementConfig}
        value={el.config.value}
        required={el.config.validation.required}
        changed={(event) => this.inputChangedHandler(event, el.id)}
      />
    ));

    return (
      <main className={`${classes.main} container`}>
        <div className={classes.login_form}>
          <form onSubmit={this.submitHandler}>
            {form}
            <Button btnType="success">アカウント作成</Button>
            <p>
              <NavLink to="/login" className={classes.form__link}>
                アカウントをお持ちの方はこちら
              </NavLink>
            </p>
          </form>
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    message: state.auth.userMsg,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSignupAuth: (name, password, passwordConfirm) =>
      dispatch(actionCreators.initSignupAuth(name, password, passwordConfirm)),
    onResetErrorMsg: () => dispatch(actionCreators.resetErrorMsg()),
  };
};

Signup = compose(
  withAlert(),
  connect(mapStateToProps, mapDispatchToProps)
)(Signup);

export default Signup;
