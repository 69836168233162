import * as actionTypes from "./../actions/actionTypes";
import updateObject from "./../../utility/Share/updateObject";
import {
  sortPlace,
  searchNameGenre,
  convertEnToJa,
  searchPrefecturesPlaces,
} from "./../../utility/Share/utilFunc";

const initialState = {
  places: [],
  results: [],
  // いいね　Bookmark
  placeMsg: {
    add: null,
    delete: null,
  },
  // お気に入りの場所の新規追加、編集、削除
  placeDataMsg: {
    add: null,
    edit: null,
    delete: null,
  },
};

const reducer = (state = initialState, action) => {
  if (action.type === actionTypes.GET_ALL_PLACES) {
    // state.placesを作成日順に並び替え
    const places = sortPlace([...action.places], 1);

    return updateObject(state, {
      places,
      results: places,
    });
  }

  if (action.type === actionTypes.SORT_PLACES_CREATE) {
    // state.placesを作成日順に並び替え
    const results = sortPlace([...state.results], 1);

    return updateObject(state, {
      results,
    });
  }

  if (action.type === actionTypes.SORT_PLACES_LIKE) {
    // state.placesをいいね順に並び替え
    const results = sortPlace([...state.results], 2);

    return updateObject(state, {
      results,
    });
  }

  if (action.type === actionTypes.SORT_PLACES_COMMENT) {
    // state.placesをいいね順に並び替え
    const results = sortPlace([...state.results], 3);

    return updateObject(state, {
      results,
    });
  }

  if (action.type === actionTypes.SEARCH_KEYWORD) {
    const results = searchNameGenre([...state.results], action.keyword);

    return updateObject(state, {
      results,
    });
  }

  if (action.type === actionTypes.PICK_PREFECTURES) {
    const jaPrefectures = convertEnToJa(action.prefectures);

    const results = searchPrefecturesPlaces([...state.results], jaPrefectures);

    return updateObject(state, {
      results,
    });
  }

  if (action.type === actionTypes.CREATE_PLACE) {
    return {
      ...state,
      places: [...state.places, action.place],
      results: [...state.results, action.place],
    };
  }

  if (action.type === actionTypes.GET_PLACE) {
    return {
      ...state,
      places: [action.place],
      results: [action.place],
    };
  }

  if (action.type === actionTypes.EDIT_PLACE) {
    return {
      ...state,
      places: [...state.places].map((place) => {
        if (place.id === action.placeId) {
          return action.place;
        } else {
          return place;
        }
      }),
    };
  }

  if (
    action.type === actionTypes.ADD_LIKE ||
    action.type === actionTypes.ERROR_ADD_LIKE
  ) {
    return {
      ...state,
      placeMsg: {
        ...state.placeMsg,
        add: action.status,
      },
    };
  }

  if (
    action.type === actionTypes.DELETE_LIKE ||
    action.type === actionTypes.ERROR_DELETE_LIKE
  ) {
    return {
      ...state,
      placeMsg: {
        ...state.placeMsg,
        delete: action.status,
      },
    };
  }

  if (action.type === actionTypes.RESET_LIKE) {
    return {
      ...state,
      placeMsg: {
        add: action.status,
        delete: action.status,
      },
    };
  }

  if (action.type === actionTypes.CREATE_COMMENT) {
    // 対象のお気に入りの場所のindexを取得
    const placeIndex = state.places.findIndex(
      (p) => p.id === action.comment.place_id
    );

    return {
      ...state,
      places: [...state.places].map((place, i) => {
        if (i === placeIndex) {
          return {
            ...state.places[placeIndex],
            comments: state.places[placeIndex].comments.concat(action.comment),
          };
        } else {
          return place;
        }
      }),
    };
  }

  if (action.type === actionTypes.DELETE_SELF_PLACE) {
    return {
      ...state,
      places: [...state.places].filter((p) => p._id !== action.placeId),
      results: [...state.results].filter((p) => p._id !== action.placeId),
      placeDataMsg: {
        ...state.placeDataMsg,
        delete: action.status,
      },
    };
  }

  if (
    action.type === actionTypes.ERROR_DELETE_SELF_PLACE ||
    action.type === actionTypes.RESET_DELETE_SELF_PLACE
  ) {
    return {
      ...state,
      placeDataMsg: {
        ...state.placeDataMsg,
        delete: action.status,
      },
    };
  }

  return state;
};

export default reducer;
