import React from "react";

import Button from "./../../UI/Button/Button";
import Dropdown from "./../../UI/Dropdown/Dropdown";
import SearchIcon from "@material-ui/icons/Search";

import classes from "./SearchForm.module.scss";

const searchForm = (props) => {
  return (
    <div className={classes.form__search}>
      <div className={classes.sort_box}>
        <Button
          btnType="success"
          styleType="btn__prefectures"
          clicked={props.prefectureClick}
        >
          都道府県から探す
        </Button>
        <Dropdown sortClick={props.sortClick}>並び替え</Dropdown>
      </div>
      <form onSubmit={props.submit} className={classes.search_box}>
        <SearchIcon
          onClick={props.submit}
          className={classes.search__icon_btn}
        />
        <input
          type="text"
          id="search"
          className={classes.form__input}
          placeholder="場所名・ジャンル"
          onChange={props.change}
        />
      </form>
    </div>
  );
};

export default searchForm;
