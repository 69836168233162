import React, { Component } from "react";
import { connect } from "react-redux";

import Auxiliary from "./../Auxiliary/Auxiliary";
import Header from "./../../components/Navigation/Header/Header";
import Footer from "./../../components/Navigation/Footer/Footer";
import SideDrawer from "./../../components/UI/SideDrawer/SideDrawer";

class Layout extends Component {
  state = {
    showSideDrawer: false,
  };

  sideDrawerClosedHandler = () => {
    this.setState({ showSideDrawer: false });
  };

  sideDrawerOpenHandler = () => {
    this.setState({ showSideDrawer: true });
  };

  render() {
    return (
      <Auxiliary>
        <Header opened={this.sideDrawerOpenHandler} />
        <SideDrawer
          open={this.state.showSideDrawer}
          closed={this.sideDrawerClosedHandler}
        />
        {this.props.children}
        <Footer />
      </Auxiliary>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(Layout);
