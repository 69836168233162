import updateObject from "./../Share/updateObject";
import checkValidity from "./../Share/checkValidity";

export const inputChangedHandlerFunc = (state, event, controlName) => {
  const updatedControls = updateObject(state.controls, {
    [controlName]: updateObject(state.controls[controlName], {
      value: event.target.value,
      valid: checkValidity(
        event.target.value,
        state.controls[controlName].validation
      ),
      touched: true,
    }),
  });
  return updatedControls;
};
