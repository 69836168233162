import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Layout from "./hoc/Layout/Layout";
import About from "./pages/About/About";
import Index from "./pages/Index/Index";
import Login from "./pages/Login/Login";
import Signup from "./pages/Signup/Signup";
import Contact from "./pages/Contact/Contact";
import Place from "./pages/Place/Place";
import Setting from "./pages/Setting/Setting";

// Leaflet style
import "leaflet/dist/leaflet.css";

class App extends Component {
  render() {
    let routes = (
      <Switch>
        <Route path="/settings" component={Setting} />
        <Route path="/about" component={About} />
        <Route path="/place/:id" component={Place} />
        <Route path="/login" component={Login} />
        <Route path="/signup" component={Signup} />
        <Route path="/contact" component={Contact} />
        <Route path="/" component={Index} />
        <Redirect to="/" />
      </Switch>
    );

    return <Layout>{routes}</Layout>;
  }
}

export default App;
