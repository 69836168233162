import React from "react";

import MenuIcon from "@material-ui/icons/Menu";

import classes from "./MenuIcon.module.scss";

const menuIcon = (props) => (
  <div className={classes.MenuIcon} onClick={props.opened}>
    <MenuIcon className={classes.icon} />
  </div>
);

export default menuIcon;
