import React from "react";
import { NavLink } from "react-router-dom";

import placeShareLogo from "./../../../assets/images/place-share-logo.png";
import "./FooterLogo.scss";

const footerLogo = (props) => (
  <nav>
    <NavLink to="/" className="FooterLogo">
      <img src={placeShareLogo} alt="PlaceShareLogo" />
    </NavLink>
  </nav>
);

export default footerLogo;
