import React from "react";
import { NavLink } from "react-router-dom";

import "./HeaderNavItem.scss";

const headerNavItem = (props) => (
  <li>
    <NavLink onClick={props.clicked} to={props.link} className="nav__el">
      {props.children}
    </NavLink>
  </li>
);

export default headerNavItem;
