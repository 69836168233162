import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import MuiPagination from "@material-ui/lab/Pagination";
import { withStyles } from "@material-ui/core/styles";
import { withAlert } from "react-alert";

import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

import Spinner from "./../../components/UI/Spinner/Spinner";
import Auxiliary from "./../../hoc/Auxiliary/Auxiliary";
import PlaceCard from "../../components/PlaceCard/PlaceCard";
import SearchForm from "./../../components/Form/SerchForm/SerchForm";
import PrefectureModal from "./../../components/Form/PrefectureModal/PrefectureModal";
import AddPlaceForm from "../../components/Form/AddPlaceForm/AddPlaceForm";

import classes from "./index.module.scss";

import * as actionCreators from "./../../store/actions/index";
import { arrayChunk, isObjEmpty } from "./../../utility/Share/utilFunc";
import updateObject from "./../../utility/Share/updateObject";

export class Index extends Component {
  state = {
    totalPage: null,
    page: 1,
    perPage: 6,
    showPrefectureModal: false,
    showAddPlaceForm: false,
    bookmarkClicked: {},
    likeClicked: {},
    search: {
      prefectures: [],
      sort: "",
      keyword: "",
    },
  };

  addFormOpenHandler = () => {
    this.setState({ showAddPlaceForm: true });
  };

  closedFormOpenHandler = () => {
    this.setState({ showAddPlaceForm: false });
  };

  prefectureModalOpenHandler = (e) => {
    e.preventDefault();
    this.setState({ showPrefectureModal: true });
  };

  prefectureModalClosedHandler = () => {
    this.setState({ showPrefectureModal: false });
  };

  paginationChangeHandler = (e, page) => {
    window.scrollTo(0, 0);
    this.setState({ page });
  };

  bookmarkClickHandler = async (id) => {
    // サーバに対象のお気に入りの場所にいいねがあったことを知らせる
    const userId = this.props.user.id;
    this.state.bookmarkClicked[id]
      ? await this.props.onDeleteBookmark(id, userId)
      : await this.props.onAddBookmark(id, userId);

    const alert = this.props.alert;

    // Bookmark 追加 alert
    if (this.props.bookmarkMsg.add === true) {
      alert.success("「ブックマークしたお気に入りの場所」に追加しました");
    }

    if (this.props.bookmarkMsg.add === false) {
      alert.error("「ブックマークしたお気に入りの場所」の追加に失敗しました");

      // redux state reset
      this.props.onResetBookmark();
      return;
    }

    // bookmark 削除 alert
    if (this.props.bookmarkMsg.delete === true) {
      alert.success("「ブックマークしたお気に入りの場所」から削除しました");
    }

    if (this.props.bookmarkMsg.delete === false) {
      alert.error("「ブックマークしたお気に入りの場所」の削除に失敗しました");

      // redux state reset
      this.props.onResetBookmark();
      return;
    }

    // redux state reset
    this.props.onResetBookmark();

    this.setState((prevState) => ({
      bookmarkClicked: {
        ...prevState.bookmarkClicked,
        [id]: !prevState.bookmarkClicked[id],
      },
    }));
  };

  likeClickHandler = async (id) => {
    // サーバに対象のお気に入りの場所にいいねがあったことを知らせる
    this.state.likeClicked[id].liked
      ? await this.props.onDeleteLike(id)
      : await this.props.onAddLike(id);

    const alert = this.props.alert;

    // いいねをしました
    if (this.props.likeMsg.add === true) {
      alert.success("いいねをしました");
    }

    if (this.props.likeMsg.add === false) {
      alert.error("いいねに失敗しました");

      // redux state reset
      this.props.onResetLike();
      return;
    }

    // いいねを取り消しました
    if (this.props.likeMsg.delete === true) {
      alert.success("いいねを取り消しました");
    }

    if (this.props.likeMsg.delete === false) {
      alert.error("いいねの取り消しに失敗しました");

      // redux state reset
      this.props.onResetLike();
      return;
    }

    // redux state reset
    this.props.onResetLike();

    this.setState((prevState) => ({
      likeClicked: {
        ...prevState.likeClicked,
        [id]: {
          count:
            prevState.likeClicked[id].count +
            (prevState.likeClicked[id].liked ? -1 : 1),
          liked: !prevState.likeClicked[id].liked,
        },
      },
    }));
  };

  execSort = (type = "create") => {
    if (type === "create") {
      this.props.onSortPlacesCreate();
    }
    if (type === "like") {
      this.props.onSortPlacesLike();
    }
    if (type === "comment") {
      this.props.onSortPlacesComment();
    }
  };

  sortClickHandler = (e) => {
    this.setState({ page: 1 });

    // 並び替え
    this.execSort(e.value);

    this.setState({
      search: updateObject(this.state.search, {
        sort: e.value,
      }),
    });
  };

  inputKeywordChangeHandler = (e) => {
    this.setState({
      search: {
        ...this.state.search,
        keyword: e.target.value,
      },
    });
  };

  inputKeywordSubmitHandler = async (e) => {
    e.preventDefault();
    this.setState({ page: 1 });

    if (e.type === "click" || e.type === "submit") {
      if (this.state.search.keyword !== "") {
        await this.props.onInitPlaces();
        await this.props.onSearchKeyword(this.state.search.keyword);
      } else {
        await this.props.onInitPlaces();
      }
    }

    // 都道府県から探す
    if (this.state.search.prefectures.length !== 0) {
      await this.props.onPickPrefectures(this.state.search.prefectures);
    }

    // 並び替え
    this.execSort(this.state.search.sort);
  };

  prefectureChangeHandler = (e) => {
    if (this.state.search.prefectures.includes(e.target.value)) {
      this.setState({
        search: {
          ...this.state.search,
          prefectures: this.state.search.prefectures.filter(
            (prefe) => prefe !== e.target.value
          ),
        },
      });
    } else {
      this.setState({
        search: {
          ...this.state.search,
          prefectures: [...this.state.search.prefectures, e.target.value],
        },
      });
    }
  };

  prefectureSubmitHandler = async (e) => {
    e.preventDefault();
    this.setState({ page: 1 });

    if (this.state.search.prefectures.length !== 0) {
      await this.props.onInitPlaces();
      await this.props.onPickPrefectures(this.state.search.prefectures);
    } else {
      await this.props.onInitPlaces();
    }

    // キーワード検索
    if (this.state.search.keyword !== "") {
      await this.props.onSearchKeyword(this.state.search.keyword);
    }

    // 並び替え
    await this.execSort(this.state.search.sort);

    this.setState({ showPrefectureModal: false });
  };

  async componentDidMount() {
    await this.props.onInitPlaces();
    const totalPage = Math.ceil(this.props.place.length / this.state.perPage);

    let userBookmarkId = [];
    if (!isObjEmpty(this.props.user)) {
      if (this.props.user.bookmarks !== undefined) {
        userBookmarkId = this.props.user.bookmarks.map((b) => b.place_id);
      }
    }

    this.props.place.forEach((p) => {
      const key = p.id;

      const bookmarked = userBookmarkId.includes(key);

      const likeNum = p.likes.length;

      this.setState((prevState) => ({
        // bookmark clicked
        bookmarkClicked: {
          ...prevState.bookmarkClicked,
          // [key]: false,
          [key]: bookmarked ? true : false,
        },
        // like clicked
        likeClicked: {
          ...prevState.likeClicked,
          [key]: {
            count: likeNum,
            liked: false,
          },
        },
      }));
    });

    // pagination total page number
    this.setState({
      totalPage: totalPage === 0 ? 1 : totalPage,
    });
  }

  async componentDidUpdate(prevProps) {
    // お気に入りの場所が新規追加されたときに実施される処理
    if (prevProps.place.length !== this.props.place.length) {
      // page
      const totalPage = Math.ceil(this.props.place.length / this.state.perPage);
      this.setState({
        totalPage: totalPage === 0 ? 1 : totalPage,
      });

      let userBookmarkId = [];
      if (!isObjEmpty(this.props.user)) {
        if (this.props.user.bookmarks !== undefined) {
          userBookmarkId = this.props.user.bookmarks.map((b) => b.place_id);
        }
      }

      this.props.place.forEach((p) => {
        const key = p.id;

        const bookmarked = userBookmarkId.includes(key);

        const likeNum = p.hasOwnProperty("likes") ? p.likes.length : 0;

        this.setState((prevState) => ({
          // bookmark clicked
          bookmarkClicked: {
            ...prevState.bookmarkClicked,
            // [key]: false,
            [key]: bookmarked ? true : false,
          },
          // like clicked
          likeClicked: {
            ...prevState.likeClicked,
            [key]: {
              count: likeNum,
              liked: false,
            },
          },
        }));
      });
    }
  }

  render() {
    let addBtn = null;
    if (!isObjEmpty(this.props.user)) {
      addBtn = (
        <div onClick={this.addFormOpenHandler}>
          <AddCircleOutlineIcon className={classes.add__place_btn} />
        </div>
      );
    }

    // Paginatation セットアップ
    const Pagination = withStyles({
      root: {
        //中央寄せのためインラインブロックに変更
        display: "inline-block",
        marginBottom: "3rem",
      },
    })(MuiPagination);

    let places = (
      <div className="main-container">
        <Spinner />
      </div>
    );

    // place card のセッティング
    let placeCards = null;
    if (this.state.totalPage !== null) {
      // dropdwon input checkboxで選択されたplaceを第一引数に指定
      const tempPlaceLen = arrayChunk(this.props.place, this.state.perPage);
      if (tempPlaceLen.length !== 0) {
        placeCards = tempPlaceLen[this.state.page - 1].map((card, i) => (
          <PlaceCard
            card={card}
            key={i + 1}
            user={this.props.user}
            bookmarkClickHandler={(id) => this.bookmarkClickHandler(id)}
            bookmarkClicked={this.state.bookmarkClicked}
            likeClickHandler={(id) => this.likeClickHandler(id)}
            likeClicked={this.state.likeClicked}
          />
        ));
      }
    }

    if (this.props.place.length !== 0) {
      places = (
        <div>
          <div className={classes.form_container}>
            <SearchForm
              prefectureClick={this.prefectureModalOpenHandler}
              sortClick={this.sortClickHandler}
              submit={this.inputKeywordSubmitHandler}
              change={this.inputKeywordChangeHandler}
            />
          </div>
          <h2 className={classes.search_num}>
            {this.props.place.length} places
          </h2>
          <div className={classes.card_container}>
            {placeCards === null ? "" : placeCards}
          </div>
          <div
            className="pagination-container"
            style={{ textAlign: "center", backgroundColor: "#f7f7f7" }}
          >
            <Pagination
              count={this.state.totalPage} //総ページ数
              variant="outlined"
              color="primary" //ページネーションの色
              onChange={(e, page) => this.paginationChangeHandler(e, page)} //変更されたときに走る関数。第2引数にページ番号が入る
              page={this.state.page} //現在のページ番号
              size="large"
            />
          </div>
          <PrefectureModal
            open={this.state.showPrefectureModal}
            closed={this.prefectureModalClosedHandler}
            submit={this.prefectureSubmitHandler}
            change={this.prefectureChangeHandler}
          />
        </div>
      );
    }

    return (
      <Auxiliary>
        {addBtn}
        {places}
        <AddPlaceForm
          open={this.state.showAddPlaceForm}
          closed={this.closedFormOpenHandler}
          formType="new"
        />
      </Auxiliary>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    place: state.place.results,
    likeMsg: state.place.placeMsg,
    user: state.auth.user,
    bookmarkMsg: state.auth.userMsg,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onInitPlaces: () => dispatch(actionCreators.initPlaces()),
    onSortPlacesCreate: () => dispatch(actionCreators.sortPlacesCreate()),
    onSortPlacesLike: () => dispatch(actionCreators.sortPlacesLike()),
    onSortPlacesComment: () => dispatch(actionCreators.sortPlacesComment()),
    onSearchKeyword: (keyword) =>
      dispatch(actionCreators.searchKeyword(keyword)),
    onPickPrefectures: (prefectures) =>
      dispatch(actionCreators.pickPrefectures(prefectures)),
    onAddLike: (id) => dispatch(actionCreators.addLike(id)),
    onDeleteLike: (id) => dispatch(actionCreators.deleteLike(id)),
    onResetLike: () => dispatch(actionCreators.resetLike()),
    onAddBookmark: (id, userId) =>
      dispatch(actionCreators.initAddBookmark(id, userId)),
    onDeleteBookmark: (id, userId) =>
      dispatch(actionCreators.initDeleteBookmark(id, userId)),
    onResetBookmark: () => dispatch(actionCreators.resetBookmark()),
  };
};

Index = compose(
  withAlert(),
  connect(mapStateToProps, mapDispatchToProps)
)(Index);

export default Index;
