import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withAlert } from 'react-alert';

import Auxiliary from './../../../hoc/Auxiliary/Auxiliary';
import Button from './../../UI/Button/Button';
import Input from './../../UI/Input/Input';
import { inputChangedHandlerFunc } from './../../../utility/InputChangeHandler/InputChangeHandler';
import updateObject from './../../../utility/Share/updateObject';

import classes from './CategoryModal.module.scss';

import * as actionCreators from './../../../store/actions/index';

class CategoryModal extends Component {
  state = {
    controls: {
      category: {
        elementType: 'input',
        label: 'カテゴリ名',
        elementConfig: {
          type: 'text',
          id: 'category',
          name: 'category',
        },
        value: '',
        validation: {
          required: true,
        },
      },
    },
  };
  _items = React.createRef();

  submitHandler = async (e) => {
    e.preventDefault();
    const alert = this.props.alert;

    // ユーザの取得
    const userId = this.props.user.id;
    // カテゴリの名前とIDを取得
    let categoryName;
    let categoryId;
    if (this.props.type === 'new') {
      categoryName = this.state.controls.category.value;
    }
    if (this.props.type === 'edit') {
      categoryName = this._items.current.value;
      categoryId = this.props.selectedCategory._id;
    }
    if (this.props.type === 'delete') {
      categoryId = this.props.selectedCategory._id;
    }

    try {
      this.props.closed();

      if (this.props.type === 'new' || this.props.type === 'edit') {
        // フォーム内の値を削除
        this.setState({
          controls: updateObject(this.state.controls, {
            category: updateObject(this.state.controls.category, {
              value: '',
            }),
          }),
        });

        this._items.current.value = '';
      }

      if (this.props.type === 'new') {
        // dispatch server カテゴリの新規作成
        await this.props.onCreateCategory(categoryName, userId);

        // alert
        alert.success('新しいカテゴリを作成することに成功しました');
      }

      if (this.props.type === 'edit') {
        // dispatch server カテゴリの名前編集
        await this.props.onEdtCategoryName(categoryName, categoryId);

        // alert
        alert.success('カテゴリを編集することに成功しました');
      }

      if (this.props.type === 'delete') {
        // dispatch server カテゴリの削除
        await this.props.onDeleteCategory(categoryId);

        // alert
        alert.success('カテゴリを削除することに成功しました');
      }
    } catch (error) {
      this.props.closed();

      if (this.props.type === 'new') {
        // alert
        alert.error('新しいカテゴリを作成することに失敗しました');
      }
      if (this.props.type === 'edit') {
        // alert
        alert.error('カテゴリを編集することに失敗しました');
      }
      if (this.props.type === 'delete') {
        // alert
        alert.error('カテゴリを削除することに失敗しました');
      }
    }
  };

  inputChangedHandler = (event, controlName) => {
    if (this.props.type === 'edit') return;
    this.setState({
      controls: inputChangedHandlerFunc(this.state, event, controlName),
    });
  };

  componentDidUpdate(prevProps) {
    if (this.props.selectedCategory !== prevProps.selectedCategory) {
      this.setState({
        controls: updateObject(this.state.controls, {
          category: updateObject(this.state.controls.category, {
            value:
              this.props.selectedCategory === undefined
                ? ''
                : this.props.selectedCategory.name,
          }),
        }),
      });

      if (this._items.current === null) return;

      this._items.current.value =
        this.props.selectedCategory === undefined
          ? ''
          : this.props.selectedCategory.name;
    }
  }

  render() {
    const { category } = this.state.controls;
    // フォームの有無
    let formClasses = [classes.delete_place_window, classes.hidden];
    let overlayClasses = [classes.overlay, classes.hidden];

    if (this.props.open) {
      formClasses = [classes.delete_place_window];
      overlayClasses = [classes.overlay];
    }

    // new edit delete フォーム条件分岐
    let heading;
    let categoryInput;
    let categoryBtn;
    if (this.props.type === 'new') {
      heading = '新しいカテゴリを作成';
      categoryInput = (
        <Input
          elementType={category.elementType}
          label={category.label}
          htmlFor={category.elementConfig.id}
          elementConfig={category.elementConfig}
          value={category.value}
          required={category.validation.required}
          changed={(event) =>
            this.inputChangedHandler(event, category.elementConfig.id)
          }
          refProp={this._items}
        />
      );
      categoryBtn = (
        <Button
          btnType="success"
          styleType="flex_btn"
          // clicked={() => this.submitHandler()}
        >
          作成
        </Button>
      );
    }
    if (this.props.type === 'edit') {
      heading = 'カテゴリを編集';
      categoryInput = (
        <Input
          elementType={category.elementType}
          label={category.label}
          htmlFor={category.elementConfig.id}
          elementConfig={category.elementConfig}
          value={category.value}
          required={category.validation.required}
          changed={(event) =>
            this.inputChangedHandler(event, category.elementConfig.id)
          }
          refProp={this._items}
        />
      );
      categoryBtn = (
        <Button
          btnType="success"
          styleType="flex_btn"
          // clicked={() => this.submitHandler()}
        >
          編集
        </Button>
      );
    }
    if (this.props.type === 'delete') {
      heading = 'カテゴリを削除してもよろしいですか';
      categoryBtn = (
        <Button
          btnType="danger"
          styleType="flex_btn"
          // clicked={() => this.submitHandler()}
        >
          削除
        </Button>
      );
    }

    return (
      <Auxiliary>
        <div
          className={overlayClasses.join(' ')}
          onClick={this.props.closed}
        ></div>
        <div className={formClasses.join(' ')}>
          <button
            className={classes.btn__close_modal}
            onClick={this.props.closed}
          >
            x
          </button>
          <form className={classes.delete} onSubmit={this.submitHandler}>
            <h3 className={classes.delete__heading}>{heading}</h3>
            {categoryInput}
            <div className={classes.btn_box}>
              {categoryBtn}
              {/* <Button btnType="cencel" clicked={this.props.closed}>
                キャンセル
              </Button> */}
            </div>
          </form>
        </div>
      </Auxiliary>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onCreateCategory: (name, userId) =>
      dispatch(actionCreators.createCategory(name, userId)),
    onEdtCategoryName: (name, categoryId) =>
      dispatch(actionCreators.edtCategoryName(name, categoryId)),
    onDeleteCategory: (categoryId) =>
      dispatch(actionCreators.deleteCategory(categoryId)),
  };
};

CategoryModal = compose(
  withAlert(),
  connect(null, mapDispatchToProps)
)(CategoryModal);

export default CategoryModal;
