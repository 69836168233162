import React from "react";

import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

import Auxiliary from "./../../../../hoc/Auxiliary/Auxiliary";

import { config } from "./../../../../config";

import defaultImg from "./../../../../assets/images/default.png";
import userDefaultImg from "./../../../../assets/images/userDefault.png";
import classes from "./ImageInput.module.scss";

const imageInput = (props) => {
  let previewImgEl;
  let inputEl;
  let userImage;

  // userImage
  if (props.user !== undefined) {
    if (props.user === "default.jpg") {
      userImage = userDefaultImg;
    } else {
      userImage = `${config.s3.URL}/users/${props.user.photo}`;
    }
  }

  // HTML
  if (props.changeType === "user_info") {
    inputEl = (
      <input
        type="file"
        name="images"
        id="user_info_image"
        className={classes.form__upload_image}
        accept="image/*"
        onChange={props.changed}
      />
    );
    previewImgEl = (
      <div className={classes.img_box}>
        <img
          src={props.images[0] ? props.images[0].previewUrl : userImage}
          data-num="1"
          alt=""
          className={classes.upload_image}
        />
        {props.images[0] ? (
          <div
            className={classes.remove_icon_wrapper}
            onClick={() => props.remove(0)}
          >
            <HighlightOffIcon className={classes.remove_icon} />
          </div>
        ) : (
          ""
        )}
      </div>
    );
  } else {
    inputEl = (
      <input
        type="file"
        name="images"
        id="image"
        className={classes.form__upload_image}
        accept="image/*"
        multiple
        onChange={props.changed}
      />
    );
    previewImgEl = [...Array(3)].map((_, i) => {
      let imgEl;
      if (props.images[i]) {
        imgEl = (
          <Auxiliary>
            {/* eslint-disable-next-line */}
            <img
              src={props.images[i].previewUrl}
              data-num={i + 1}
              key={i}
              alt="preview image"
              className={classes.upload_image}
            />
            <div
              className={classes.remove_icon_wrapper}
              onClick={() => props.remove(i)}
            >
              <HighlightOffIcon className={classes.remove_icon} />
            </div>
          </Auxiliary>
        );
      } else {
        imgEl = (
          // eslint-disable-next-line
          <img
            src={defaultImg}
            data-num={i + 1}
            key={i}
            alt="default image"
            className={classes.upload_image}
          />
        );
      }
      return (
        <div className={classes.img_box} key={i}>
          {imgEl}
        </div>
      );
    });
  }

  return (
    <Auxiliary>
      <span className={classes.form__label}>画像</span>
      <div className={classes.img_wrapper}>
        <label
          htmlFor={
            props.changeType !== "user_info" ? "image" : "user_info_image"
          }
        >
          <AddCircleOutlineIcon className={classes.add__place_btn} />
        </label>
        <div className={classes.img_boxes}>{previewImgEl}</div>
        {props.changeType !== "user_info" ? (
          <span className={classes.upload_notion}>3枚までアップロード可能</span>
        ) : (
          ""
        )}
      </div>
      {inputEl}
    </Auxiliary>
  );
};

export default imageInput;
