// React
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunk from "redux-thunk";

import { transitions, positions, Provider as AlertProvider } from "react-alert";

// Scss
import "./index.scss";

// Share
import ScrollToTop from "./utility/ScrollToTop/ScrollToTop";
import reportWebVitals from "./reportWebVitals";

// Component
import App from "./App";

// Reducer
import placeReducer from "./store/reducers/place";
import authReducer from "./store/reducers/auth";

// Reducerの結合
const rootReducer = combineReducers({
  place: placeReducer,
  auth: authReducer,
});

// Devtoolの設定
const composeEnhancers =
  process.env.NODE_ENV === "development"
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : null || compose;

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

// react-alert optional configuration
const options = {
  // you can also just use 'bottom center'
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  offset: "30px",
  // you can also just use 'scale'
  transition: transitions.SCALE,
  containerStyle: {
    zIndex: 9999,
  },
};

const AlertTemplate = ({ options, message }) => (
  <div
    className={`alert-style ${options.type === "error" ? "alert-fail" : ""}`}
  >
    {message}
  </div>
);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <ScrollToTop />
        <AlertProvider template={AlertTemplate} {...options}>
          <App />
        </AlertProvider>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
