import React from "react";

import Auxiliary from "./../../hoc/Auxiliary/Auxiliary";

import tentMp4 from "./../../assets/images/tent.mp4";
import tentWebm from "./../../assets/images/tent.webm";
import followJpg from "./../../assets/images/follow.png";
import mapJpg from "./../../assets/images/map.png";
import searchJpg from "./../../assets/images/search.png";

import classes from "./About.module.scss";

const about = (props) => (
  <Auxiliary>
    <div className={classes.section_about}>
      <div className={classes.bg_video}>
        <video className={classes.bg_video__content} autoPlay muted loop>
          <source src={tentMp4} type="video/mp4" />
          <source src={tentWebm} type="video/mp4" />
        </video>
      </div>
      <div className={classes.section_about__heading}>
        <h2 className={classes.section_about__heading_text}>Place Share</h2>
        <p className={classes.section_about__heading_detail}>make me happy</p>
      </div>
    </div>
    <div className={classes.section_feature}>
      <div>
        <h3 className={classes.feature__heading}>
          あなたのお気に入りの場所をみんなにシェアすることができます。
        </h3>
        <div className={classes.flex_wrapper}>
          <div className={`${classes.flex_box} ${classes.mr_5}`}>
            <div className={classes.card__picture}>
              <img
                src={searchJpg}
                alt="test img"
                className={classes.card__picture_img}
              />
            </div>
            <div className={classes.detail_box}>
              <p className={classes.detail_box__text}>
                お気に入りの場所近くの宿泊施設を確認することができます。
              </p>
            </div>
          </div>
          <div className={`${classes.flex_box} ${classes.mr_5}`}>
            <div className={classes.card__picture}>
              <img
                src={followJpg}
                alt="test img"
                className={classes.card__picture_img}
              />
            </div>
            <div className={classes.detail_box}>
              <p className={classes.detail_box__text}>
                みんなのお気に入りの場所をフォローすることができます。
              </p>
            </div>
          </div>
          <div className={classes.flex_box}>
            <div className={classes.card__picture}>
              <img
                src={mapJpg}
                alt="test img"
                className={classes.card__picture_img}
              />
            </div>
            <div className={classes.detail_box}>
              <p className={classes.detail_box__text}>
                お気に入りの場所を地図一覧で確認することができます。
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Auxiliary>
);

export default about;
