const Config = () => {
  if (process.env.NODE_ENV === "development") {
    // test
    return {
      s3: {
        URL: process.env.REACT_APP_DEV_S3_OBJECT_URL,
      },
      restful: {
        apiURL: process.env.REACT_APP_DEV_SERVER_URL,
      },
    };
  } else if (process.env.NODE_ENV === "production") {
    console.log(
      process.env.REACT_APP_PROD_SERVER_URL,
      process.env.REACT_APP_PROD_S3_OBJECT_URL
    );
    // production
    return {
      s3: {
        URL: process.env.REACT_APP_PROD_S3_OBJECT_URL,
      },
      restful: {
        apiURL: process.env.REACT_APP_PROD_SERVER_URL,
      },
    };
  }
  // etc
  return {};
};
export const config = Config();
