import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withAlert } from 'react-alert';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import MultiRef from 'react-multi-ref';

import Auxiliary from './../../../hoc/Auxiliary/Auxiliary';
import Button from '../Button/Button';
import LeafletMap from '../../Leaflet/SettingLeaflet/SettingLeaflet';
import SettingCard from '../../PlaceCard/SettingCard/SettingCard';
import Input from '../Input/Input';
import ImageInput from './../../UI/Input/ImageInput/ImageInput';

import PeopleIcon from '@material-ui/icons/People';
import NearMeIcon from '@material-ui/icons/NearMe';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import CategoryIcon from '@material-ui/icons/Category';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { inputChangedHandlerFunc } from './../../../utility/InputChangeHandler/InputChangeHandler';
import {
  readerOnloaded,
  contactUpdatePass,
  parseStrToBoolean,
  placeOpenArray,
  categoryPlaceOpenArray,
  initCategoryPlaceOpenArray,
} from './../../../utility/Share/utilFunc';
import updateObject from './../../../utility/Share/updateObject';

import './TabComponent.scss';

import * as actionCreators from './../../../store/actions/index';

class TabComponent extends Component {
  state = {
    controls: {
      name: {
        elementType: 'input',
        label: 'ユーザー名',
        elementConfig: {
          type: 'text',
          id: 'name',
          name: 'name',
        },
        value: `${this.props.userName}`,
        validation: {
          required: true,
        },
        userInfoStyle: true,
      },
      passwordCurrent: {
        elementType: 'input',
        label: '現在のパスワード',
        elementConfig: {
          type: 'password',
          id: 'passwordCurrent',
          name: 'passwordCurrent',
          placeholder: '••••••••',
          minLength: '8',
        },
        value: '',
        validation: {
          required: true,
        },
        userInfoStyle: true,
      },
      password: {
        elementType: 'input',
        label: '新しいパスワード',
        elementConfig: {
          type: 'password',
          id: 'password',
          name: 'password',
          placeholder: '••••••••',
          minLength: '8',
        },
        value: '',
        validation: {
          required: true,
        },
        userInfoStyle: true,
      },
      passwordConfirm: {
        elementType: 'input',
        label: '新しいパスワードの確認',
        elementConfig: {
          type: 'password',
          id: 'passwordConfirm',
          name: 'passwordConfirm',
          placeholder: '••••••••',
          minLength: '8',
        },
        value: '',
        validation: {
          required: true,
        },
        userInfoStyle: true,
      },
    },
    images: [],
    categorySelfOpen: [],
    categoryBookmarkOpen: [],
    categoryPlaceOpen: [],
  };
  _items = new MultiRef();

  categoryBoxHandler = (e) => {
    const { bookmarkFlg, placeIndex } = e.currentTarget.dataset;

    parseStrToBoolean(bookmarkFlg) === true
      ? this.setState({
          categoryBookmarkOpen: placeOpenArray(
            this.state.categoryBookmarkOpen,
            placeIndex
          ),
        })
      : this.setState({
          categorySelfOpen: placeOpenArray(
            this.state.categorySelfOpen,
            placeIndex
          ),
        });
  };

  categoryPlaceBoxHandler = (e) => {
    const { categoryIndex, placeIndex } = e.currentTarget.dataset;

    this.setState({
      categoryPlaceOpen: categoryPlaceOpenArray(
        this.state.categoryPlaceOpen,
        categoryIndex,
        placeIndex
      ),
    });
  };

  inputChangedHandler = (event, controlName) => {
    this.setState({
      controls: inputChangedHandlerFunc(this.state, event, controlName),
    });
  };

  fileChangedHander = async (files) => {
    // ファイルをFileReaderに読み込ませる
    const reader = await Promise.all(
      [...files].map(async (file) => {
        const r = await readerOnloaded(file);
        return { file, previewUrl: r.result };
      })
    );

    // stateに保存
    this.setState({ images: [...this.state.images, ...reader] });
  };

  // 削除
  fileRemovedHandler = (index) => {
    this.setState({ images: this.state.images.filter((_, i) => i !== index) });
  };

  userSubmitHandler = async (event) => {
    event.preventDefault();
    const alert = this.props.alert;
    const formData = new FormData();
    const { name } = this.state.controls;

    formData.append(name.elementConfig.name, name.value);

    if (this.state.images.length !== 0) {
      this.state.images.forEach((image) =>
        formData.append('images', image.file)
      );
    }

    formData.append('user', this.props.user._id);

    try {
      await this.props.onUpdateMe(formData);

      alert.success('ユーザ情報の更新に成功しました');
    } catch (error) {
      alert.error('ユーザ情報の更新に失敗しました');
    }
  };

  passwordFormDeleteHandler = () => {
    // stateの削除
    Object.keys(this.state.controls).forEach((key) => {
      if (key !== 'name') {
        this.setState({
          controls: updateObject(this.state.controls, {
            [key]: updateObject(this.state.controls[key], {
              value: '',
            }),
          }),
        });
      }
    });
    // inputの削除
    this._items.map.forEach((item) => {
      if (item.name !== 'name') {
        item.value = '';
      }
    });
  };

  passwordSubmitHandler = async (event) => {
    event.preventDefault();
    const alert = this.props.alert;
    let passData = {};

    Object.keys(this.state.controls).forEach((key) => {
      if (key !== 'name') {
        passData[key] = this.state.controls[key].value;
      }
    });

    try {
      await contactUpdatePass(passData);
      // 成功のメッセージ
      alert.success('パスワードの更新に成功しました');
      // stateの削除
      this.passwordFormDeleteHandler();
    } catch (error) {
      // 失敗のメッセージ
      alert.error('パスワードの更新に失敗しました');
      // stateの削除
      this.passwordFormDeleteHandler();
    }
  };

  componentDidMount() {
    const boxArrSelf = Array(this.props.selfPlaces.length).fill(false);
    const boxArrBookmark = Array(this.props.bookmarkPlaces.length).fill(false);

    const boxArrCategory = initCategoryPlaceOpenArray(
      this.props.categoryPlaces,
      false
    );

    this.setState({
      categorySelfOpen: boxArrSelf,
      categoryBookmarkOpen: boxArrBookmark,
      categoryPlaceOpen: boxArrCategory,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.categoryPlaces.length !== this.props.categoryPlaces.length) {
      const boxArrCategory = initCategoryPlaceOpenArray(
        this.props.categoryPlaces,
        false
      );
      this.setState({
        categoryPlaceOpen: boxArrCategory,
      });
    }
  }

  render() {
    const formElArray = [];
    for (const key in this.state.controls) {
      formElArray.push({
        id: key,
        config: this.state.controls[key],
      });
    }
    let form = formElArray.map((el) => (
      <Input
        key={el.id}
        elementType={el.config.elementType}
        label={el.config.label}
        htmlFor={el.config.elementConfig.id}
        elementConfig={el.config.elementConfig}
        value={el.config.value}
        userInfoStyle={el.config.userInfoStyle}
        changed={(event) => this.inputChangedHandler(event, el.id)}
        refProp={this._items.ref(el.id)}
      />
    ));
    // categoryの表示
    let categoryEl;
    let categoryPanel;
    if (this.props.categoryFlg === false) {
      categoryEl = '';
    } else {
      categoryEl = (
        <Auxiliary>
          <div
            className="react-tabs__tab"
            onClick={(e) => this.props.categoryClick(e, 'new')}
          >
            <AddIcon className="icon_style" />
            <span className="list_style">New</span>
          </div>
          {this.props.categoryPlaces.map((c, i) => (
            <Tab key={i} disabled={c.place_id.length !== 0 ? false : true}>
              <span className="list_style">{c.name}</span>
              <div
                onClick={(e) => this.props.categoryClick(e, 'edit')}
                style={{ display: 'inline' }}
                data-category-index={i}
              >
                <EditIcon className="icon_style--category" />
              </div>
              <div
                onClick={(e) => this.props.categoryClick(e, 'delete')}
                style={{ display: 'inline' }}
                data-category-index={i}
              >
                <DeleteOutlineIcon className="icon_style--category" />
              </div>
            </Tab>
          ))}
        </Auxiliary>
      );
      categoryPanel = this.props.categoryPlaces.map((c, i) =>
        c.place_id.length !== 0 ? (
          <TabPanel key={i}>
            <div className="panel-content">
              <div className="content">
                <LeafletMap places={c.place_id} />
              </div>
              <div className="content">
                <div className="place__card_wrapper">
                  {c.place_id.map((p, index) => (
                    <SettingCard
                      key={p.id}
                      index={index}
                      categoryIndex={i}
                      place={p}
                      categoryPlaces={this.props.categoryPlaces}
                      category={true}
                      bookmark={true}
                      deleteClick={this.props.deleteClick}
                      categoryBoxClick={this.categoryPlaceBoxHandler}
                      categoryPlaceOpen={this.state.categoryPlaceOpen[i][index]}
                    />
                  ))}
                </div>
              </div>
            </div>
          </TabPanel>
        ) : (
          <TabPanel key={i}></TabPanel>
        )
      );
    }

    return (
      <Tabs>
        <TabList>
          <Tab>
            <PeopleIcon className="icon_style" />
            <span className="list_style">ユーザー情報変更</span>
          </Tab>
          {this.props.selfPlaces.length !== 0 ? (
            <Tab>
              <NearMeIcon className="icon_style" />
              <span className="list_style">自分のお気に入りの場所</span>
            </Tab>
          ) : (
            ''
          )}
          {this.props.bookmarkPlaces.length !== 0 ? (
            <Tab>
              <BookmarkIcon className="icon_style" />
              <span className="list_style">
                ブックマークしたお気に入りの場所
              </span>
            </Tab>
          ) : (
            ''
          )}
          <Auxiliary>
            <div
              className="react-tabs__tab"
              onClick={this.props.categoryComponent}
            >
              <CategoryIcon className="icon_style" />
              <span className="list_style">カテゴリしたお気に入りの場所</span>
              <KeyboardArrowDownIcon className="icon_style--category" />
            </div>
            {categoryEl}
          </Auxiliary>
        </TabList>
        <TabPanel>
          <div className="panel-content">
            <div className="content">
              <form
                className="form_border form_tab"
                onSubmit={this.userSubmitHandler}
              >
                <h2 className="user_info_heading">ユーザー情報変更</h2>
                {form[0]}
                <ImageInput
                  changeType="user_info"
                  images={this.state.images}
                  user={this.props.user}
                  changed={(e) => this.fileChangedHander(e.target.files)}
                  remove={(index) => this.fileRemovedHandler(index)}
                />
                <Button btnType="success">保存</Button>
              </form>
            </div>
            <div className="content">
              <form className="form_tab" onSubmit={this.passwordSubmitHandler}>
                <h2 className="user_info_heading">パスワード変更</h2>
                {form[1]}
                {form[2]}
                {form[3]}
                <Button btnType="success">保存</Button>
              </form>
            </div>
          </div>
        </TabPanel>
        {this.props.selfPlaces.length !== 0 ? (
          <TabPanel>
            <div className="panel-content">
              <div className="content">
                <LeafletMap places={this.props.selfPlaces} />
              </div>
              <div className="content">
                <div className="place__card_wrapper">
                  {this.props.selfPlaces.map((p, i) => (
                    <SettingCard
                      key={p.id}
                      index={i}
                      place={p}
                      categoryPlaces={this.props.categoryPlaces}
                      bookmark={false}
                      addClick={this.props.addClick}
                      deleteClick={this.props.deleteClick}
                      categoryBoxClick={this.categoryBoxHandler}
                      categorySelfOpen={this.state.categorySelfOpen[i]}
                    />
                  ))}
                </div>
              </div>
            </div>
          </TabPanel>
        ) : (
          ''
        )}
        {this.props.bookmarkPlaces.length !== 0 ? (
          <TabPanel>
            <div className="panel-content">
              <div className="content">
                <LeafletMap places={this.props.bookmarkPlaces} />
              </div>
              <div className="content">
                <div className="place__card_wrapper">
                  {this.props.bookmarkPlaces.map((p, i) => (
                    <SettingCard
                      key={p.id}
                      index={i}
                      place={p}
                      categoryPlaces={this.props.categoryPlaces}
                      bookmark={true}
                      deleteClick={this.props.deleteClick}
                      categoryBoxClick={this.categoryBoxHandler}
                      categoryBookmarkOpen={this.state.categoryBookmarkOpen[i]}
                    />
                  ))}
                </div>
              </div>
            </div>
          </TabPanel>
        ) : (
          ''
        )}
        {this.props.categoryPlaces.length !== 0 ? categoryPanel : ''}
      </Tabs>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateMe: (formData) => dispatch(actionCreators.UpdateMe(formData)),
  };
};

TabComponent = compose(
  withAlert(),
  connect(null, mapDispatchToProps)
)(TabComponent);

export default TabComponent;
