import React from "react";

import classes from "./Button.module.scss";

const button = (props) => (
  <div>
    <button
      className={[
        classes.btn,
        classes[props.btnType],
        props.styleType ? classes[props.styleType] : "",
      ].join(" ")}
      onClick={props.clicked}
    >
      {props.children}
    </button>
  </div>
);

export default button;
