import * as actionTypes from './actionTypes';
import axios from './../../axios-instance';

export const getAllPlaces = (places) => {
  return {
    type: actionTypes.GET_ALL_PLACES,
    places,
  };
};

export const sortPlacesCreate = () => {
  return {
    type: actionTypes.SORT_PLACES_CREATE,
  };
};

export const sortPlacesLike = () => {
  return {
    type: actionTypes.SORT_PLACES_LIKE,
  };
};

export const sortPlacesComment = () => {
  return {
    type: actionTypes.SORT_PLACES_COMMENT,
  };
};

export const searchKeyword = (keyword) => {
  return {
    type: actionTypes.SEARCH_KEYWORD,
    keyword,
  };
};

export const pickPrefectures = (prefectures) => {
  return {
    type: actionTypes.PICK_PREFECTURES,
    prefectures,
  };
};

export const createPlace = (formData) => async (dispatch) => {
  try {
    const response = await axios.post('/api/v1/place', formData);

    if (response.data.status === 'success') {
      dispatch({
        type: actionTypes.CREATE_PLACE,
        place: response.data.data.data,
      });
    }
  } catch (error) {
    throw error;
  }
};

export const editPlace = (formData, placeId) => async (dispatch) => {
  try {
    const response = await axios.patch(`/api/v1/place/${placeId}`, formData);

    if (response.data.status === 'success') {
      dispatch({
        type: actionTypes.EDIT_PLACE,
        place: response.data.data.data,
        placeId,
      });
    }
  } catch (error) {
    throw error;
  }
};

export const initPlaces = () => async (dispatch) => {
  try {
    const response = await axios.get('/api/v1/place');
    dispatch(getAllPlaces(response.data.data.data));
  } catch (error) {
    throw error;
  }
};

export const getPlace = (placeId) => async (dispatch) => {
  try {
    const response = await axios.get(`/api/v1/place/${placeId}`);

    if (response.data.status === 'success') {
      dispatch({
        type: actionTypes.GET_PLACE,
        place: response.data.data.data,
        placeId,
      });
    }
  } catch (error) {
    throw error;
  }
};

export const resetLike = () => {
  return {
    type: actionTypes.RESET_LIKE,
    status: null,
  };
};

export const addLike = (id) => async (dispatch) => {
  let status;
  try {
    const response = await axios.put(`/api/v1/place/${id}/like`);

    if (response.data.status === 'success') {
      status = true;
    }

    dispatch({
      type: actionTypes.ADD_LIKE,
      status,
    });
  } catch (error) {
    status = false;
    dispatch({
      type: actionTypes.ERROR_ADD_LIKE,
      status,
    });
  }
};

export const deleteLike = (id) => async (dispatch) => {
  let status;
  try {
    const response = await axios.delete(`/api/v1/place/${id}/like`);

    if (response.status === 204) {
      status = true;
    }

    dispatch({
      type: actionTypes.DELETE_LIKE,
      status,
    });
  } catch (error) {
    status = false;
    dispatch({
      type: actionTypes.ERROR_DELETE_LIKE,
      status,
    });
  }
};

export const createComment = (data) => async (dispatch) => {
  try {
    let url = `/api/v1/place/${data.place_id}/comments`;

    // 認証あり
    if (Object.keys(data).length >= 3) {
      url = `${url}/${data.user_id}`;
    }

    const response = await axios.post(url, data);

    dispatch({
      type: actionTypes.CREATE_COMMENT,
      comment: response.data.data.data,
    });
  } catch (error) {
    throw error;
  }
};

export const deleteSelfPlace = (placeId) => async (dispatch) => {
  let status;
  try {
    const response = await axios.delete(`/api/v1/place/${placeId}`);

    if (response.status === 204) {
      status = true;
    }

    dispatch({
      type: actionTypes.DELETE_SELF_PLACE,
      status,
      placeId,
    });
  } catch (error) {
    status = false;
    dispatch({
      type: actionTypes.ERROR_DELETE_SELF_PLACE,
      status,
    });
  }
};

export const resetDeleteSelfPlace = () => {
  return {
    type: actionTypes.RESET_DELETE_SELF_PLACE,
    status: null,
  };
};
