import * as actionTypes from './actionTypes';
import axios from './../../axios-instance';

export const getUser = (user) => {
  return {
    type: actionTypes.GET_USER,
    user,
  };
};

export const errorGetUser = (error) => {
  return {
    type: actionTypes.ERROR_GET_USER,
    error,
  };
};

export const resetErrorMsg = () => {
  return {
    type: actionTypes.RESET_ERROR_MSG,
    error: null,
  };
};

export const logoutUser = () => {
  return {
    type: actionTypes.LOGOUT_USER,
    user: {},
  };
};

export const resetBookmark = () => {
  return {
    type: actionTypes.RESET_BOOKMARK,
    status: null,
  };
};

export const addBookmark = (bookmark, status) => {
  return {
    type: actionTypes.ADD_BOOKMARK,
    bookmark,
    status,
  };
};

export const deleteBookmark = (placeId, status) => {
  return {
    type: actionTypes.DELETE_BOOKMARK,
    placeId,
    status,
  };
};

export const errorAddBookmark = (status) => {
  return {
    type: actionTypes.ERROR_ADD_BOOKMARK,
    status,
  };
};

export const errorDeleteBookmark = (status) => {
  return {
    type: actionTypes.ERROR_DELETE_BOOKMARK,
    status,
  };
};

export const deleteBookmarkPlace = (placeId, userId) => async (dispatch) => {
  let status;
  try {
    const response = await axios.delete(
      `/api/v1/place/${placeId}/bookmark/${userId}`
    );

    if (response.status === 204) {
      status = true;
    }

    dispatch({
      type: actionTypes.DELETE_BOOKMARK_PLACE,
      status,
      placeId,
    });
  } catch (error) {
    status = false;
    dispatch({
      type: actionTypes.ERROR_DELETE_BOOKMARK_PLACE,
      status,
    });
  }
};

export const resetDeleteBookmark = () => {
  return {
    type: actionTypes.RESET_DELETE_BOOKMARK,
    status: null,
  };
};

export const UpdateMe = (formData) => async (dispatch) => {
  try {
    const response = await axios.patch('/api/v1/user/updateMe', formData);

    if (response.data.status === 'success') {
      dispatch({
        type: actionTypes.UPDATE_ME,
        user: response.data.data.user,
      });
    }
  } catch (error) {
    throw error;
  }
};

export const createCategory = (name, userId) => async (dispatch) => {
  try {
    const data = { name };
    const response = await axios.post(`/api/v1/place/${userId}/category`, data);

    if (response.data.status === 'success') {
      dispatch({
        type: actionTypes.CREATE_CATEGORY,
        category: response.data.data.data,
      });
    }
  } catch (error) {
    throw error;
  }
};

export const edtCategoryName = (name, categoryId) => async (dispatch) => {
  try {
    const data = { name };
    const response = await axios.patch(
      `/api/v1/place/${categoryId}/category`,
      data
    );

    if (response.data.status === 'success') {
      dispatch({
        type: actionTypes.EDIT_CATEGORY_NAME,
        category: response.data.data.data,
      });
    }
  } catch (error) {
    throw error;
  }
};

export const deleteCategory = (categoryId) => async (dispatch) => {
  try {
    const response = await axios.delete(`/api/v1/place/${categoryId}/category`);

    if (response.status === 204) {
      dispatch({
        type: actionTypes.DELETE_CATEGORY,
        categoryId,
      });
    }
  } catch (error) {
    throw error;
  }
};

export const edtCategoryPlace = (placeId, categoryId) => async (dispatch) => {
  try {
    const response = await axios.patch(
      `/api/v1/place/${categoryId}/category/${placeId}`
    );

    if (response.data.status === 'success') {
      dispatch({
        type: actionTypes.EDIT_CATEGORY_PLACE,
        category: response.data.data.data,
      });
    }
  } catch (error) {
    throw error;
  }
};

export const deleteCategoryPlace =
  (placeId, categoryId) => async (dispatch) => {
    try {
      const response = await axios.delete(
        `/api/v1/place/${categoryId}/category/${placeId}`
      );

      if (response.data.status === 'success') {
        dispatch({
          type: actionTypes.DELETE_CATEGORY_PLACE,
          categoryId,
          placeId,
        });
      }
    } catch (error) {
      throw error;
    }
  };

export const deleteCategorySelfPlace = (placeId) => {
  return {
    type: actionTypes.DELETE_CATEGORY_SELF_PLACE,
    placeId,
  };
};

export const initLoginAuth = (name, password) => async (dispatch) => {
  try {
    const data = { name, password };
    const response = await axios.post('/api/v1/user/login', data);
    dispatch(getUser(response.data.data.user));
  } catch (error) {
    dispatch(errorGetUser(error.response.data.message));
  }
};

export const initLogoutAuth = () => async (dispatch) => {
  try {
    await axios.get('/api/v1/user/logout');
    dispatch(logoutUser());
  } catch (error) {
    throw error;
  }
};

export const initSignupAuth =
  (name, password, passwordConfirm) => async (dispatch) => {
    try {
      const data = { name, password, passwordConfirm };
      const response = await axios.post('/api/v1/user/signup', data);
      dispatch(getUser(response.data.data.user));
    } catch (error) {
      dispatch(errorGetUser(error.response.data.message));
    }
  };

export const initAuth = () => async (dispatch) => {
  try {
    const response = await axios.get('/api/v1/user');
    const data = response.data.data === '' ? {} : response.data.data;
    dispatch(getUser(data));
  } catch (error) {
    dispatch(errorGetUser(error.response.data.message));
  }
};

export const initAddBookmark = (id, userId) => async (dispatch) => {
  let status;
  try {
    const response = await axios.put(`/api/v1/place/${id}/bookmark/${userId}`);

    if (response.data.status === 'success') {
      status = true;
    }

    dispatch(addBookmark(response.data.data.data, status));
  } catch (error) {
    status = false;
    dispatch(errorAddBookmark(status));
  }
};

export const initDeleteBookmark = (id, userId) => async (dispatch) => {
  let status;
  try {
    const response = await axios.delete(
      `/api/v1/place/${id}/bookmark/${userId}`
    );
    if (response.status === 204) {
      status = true;
    }

    dispatch(deleteBookmark(id, status));
  } catch (error) {
    status = false;
    dispatch(errorDeleteBookmark(status));
  }
};
