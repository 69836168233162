import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withAlert } from 'react-alert';
import MultiRef from 'react-multi-ref';

import Auxiliary from './../../../hoc/Auxiliary/Auxiliary';
import Button from './../../UI/Button/Button';
import Input from './../../UI/Input/Input';
import ImageInput from './../../UI/Input/ImageInput/ImageInput';
import { inputChangedHandlerFunc } from './../../../utility/InputChangeHandler/InputChangeHandler';
import {
  readerOnloaded,
  contactImage,
  toBlob,
} from './../../../utility/Share/utilFunc';
import updateObject from './../../../utility/Share/updateObject';

import classes from './AddPlaceForm.module.scss';

import * as actionCreators from './../../../store/actions/index';

class AddPlaceForm extends Component {
  state = {
    controls: {
      name: {
        elementType: 'input',
        label: '場所名',
        elementConfig: {
          type: 'text',
          id: 'name',
          name: 'name',
        },
        value: '',
        validation: {
          required: true,
        },
        requiredSpan: true,
      },
      address: {
        elementType: 'input',
        label: '住所',
        elementConfig: {
          type: 'text',
          placeholder: '例）〇〇県〇〇市〇〇区〇〇町〇〇ー〇〇',
          id: 'address',
          name: 'address',
        },
        value: '',
        validation: {
          required: true,
        },
        requiredSpan: true,
      },
      genre: {
        elementType: 'input',
        label: 'ジャンル',
        elementConfig: {
          type: 'text',
          name: 'genre',
          id: 'genre',
        },
        value: '',
        validation: {},
        requiredSpan: false,
      },
      memo: {
        elementType: 'textarea',
        label: 'メモ',
        elementConfig: {
          name: 'memo',
          id: 'memo',
          cols: '33',
        },
        value: '',
        validation: {},
        requiredSpan: false,
      },
    },
    images: [],
  };
  _items = new MultiRef();

  inputChangedHandler = (event, controlName) => {
    if (this.props.formType === 'edit') return;
    this.setState({
      controls: inputChangedHandlerFunc(this.state, event, controlName),
    });
  };

  filesChangedHander = async (files) => {
    // ファイルをFileReaderに読み込ませる
    const reader = await Promise.all(
      [...files].map(async (file) => {
        const r = await readerOnloaded(file);
        return { file, previewUrl: r.result };
      })
    );

    // stateに保存
    this.setState({ images: [...this.state.images, ...reader] });
  };

  // 削除
  fileRemovedHandler = (index) => {
    this.setState({ images: this.state.images.filter((_, i) => i !== index) });
  };

  formRemovedHandler = () => {
    // input textarea value 削除
    Object.keys(this.state.controls).forEach((key) => {
      this.setState({
        controls: updateObject(this.state.controls, {
          [key]: updateObject(this.state.controls[key], {
            value: '',
          }),
        }),
      });
    });
    if (this.props.formType === 'new') {
      this._items.map.forEach((item) => (item.value = ''));
    }

    // 画像の削除
    if (this.state.images.length !== 0) {
      this.setState({ images: [] });
    }
  };

  submitHandler = async (event) => {
    event.preventDefault();
    const alert = this.props.alert;
    const formData = new FormData();

    if (this.props.formType === 'new') {
      Object.keys(this.state.controls).forEach((key) => {
        formData.append(key, this.state.controls[key].value);
      });
    } else {
      this._items.map.forEach((item) => {
        formData.append(item.name, item.value);
      });
    }

    if (this.state.images.length !== 0) {
      if (this.state.images.length > 3) {
        alert.error('4枚以上の画像ファイルのアップロードはできません');
        return;
      }

      this.state.images.forEach((image) => {
        formData.append('images', image.file);
      });
    }

    formData.append('user', this.props.user.id);

    try {
      if (this.props.formType === 'new') {
        await this.props.onCreatePlace(formData);
        await this.props.onInitPlaces();
      } else {
        await this.props.onEditPlace(formData, this.props.id);
        await this.props.onInitPlaces();
      }

      this.props.closed();
      this.formRemovedHandler();

      this.props.formType === 'new'
        ? alert.success('お気に入りの場所を新規追加することに成功しました')
        : alert.success('お気に入りの場所の編集に成功しました');
    } catch (error) {
      this.props.closed();
      this.formRemovedHandler();

      this.props.formType === 'new'
        ? alert.error('お気に入りの場所を新規追加することに失敗しました')
        : alert.error('お気に入りの場所の編集に失敗しました');
    }
  };

  async componentDidUpdate(prevProps) {
    Object.keys(this.state.controls).forEach((key) => {
      if (this.props[key] === undefined) return;

      if (this.props[key] !== this.state.controls[key].value) {
        this.setState({
          controls: updateObject(this.state.controls, {
            [key]: updateObject(this.state.controls[key], {
              value: this.props[key],
            }),
          }),
        });

        this._items.map.forEach((item) => {
          if (item.name === key) {
            item.value = this.props[key];
          }
        });
      }
    });

    // 画像の元データを取得する
    if (this.props.images === undefined) return;

    if (this.props.images !== prevProps.images) {
      if (this.props.images[0] === 'defaultImg.jpg') {
        this.setState({ images: [] });
        return;
      }
      const previewUrls = await contactImage(this.props.images);

      const reader = previewUrls.map((previewUrl) => {
        // previewUrlをblob形式にする
        const file = toBlob(previewUrl);
        return { file, previewUrl };
      });

      // stateの更新
      this.setState({ images: [...reader] });
    }
  }

  render() {
    // input
    const formElArray = [];
    for (const key in this.state.controls) {
      formElArray.push({
        id: key,
        config: this.state.controls[key],
      });
    }
    let form = formElArray.map((el, i) => (
      <Input
        key={el.id}
        elementType={el.config.elementType}
        label={el.config.label}
        htmlFor={el.config.elementConfig.id}
        elementConfig={el.config.elementConfig}
        value={el.config.value}
        requiredSpan={el.config.requiredSpan}
        changed={(event) => this.inputChangedHandler(event, el.id)}
        refProp={this._items.ref(el.id)}
      />
    ));

    // フォームの有無
    let formClasses = [classes.add_place_window, classes.hidden];
    let overlayClasses = [classes.overlay, classes.hidden];

    if (this.props.open) {
      formClasses = [classes.add_place_window];
      overlayClasses = [classes.overlay];
    }

    return (
      <Auxiliary>
        <div
          className={overlayClasses.join(' ')}
          onClick={this.props.closed}
        ></div>
        <div className={formClasses.join(' ')}>
          <button
            className={classes.btn__close_modal}
            onClick={this.props.closed}
          >
            x
          </button>
          <form className={classes.upload} onSubmit={this.submitHandler}>
            <h3 className={classes.upload__heading}>
              お気に入りの場所
              <span className={classes.required}>※は必須項目</span>
            </h3>
            {form}
            <ImageInput
              changeType="edit_place"
              images={this.state.images}
              changed={(e) => this.filesChangedHander(e.target.files)}
              remove={(index) => this.fileRemovedHandler(index)}
            />
            <Button btnType="success">登録</Button>
          </form>
        </div>
      </Auxiliary>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onInitPlaces: () => dispatch(actionCreators.initPlaces()),
    onCreatePlace: (formData) => dispatch(actionCreators.createPlace(formData)),
    onEditPlace: (formData, placeId) =>
      dispatch(actionCreators.editPlace(formData, placeId)),
  };
};

AddPlaceForm = compose(
  withAlert(),
  connect(mapStateToProps, mapDispatchToProps)
)(AddPlaceForm);

export default AddPlaceForm;
