import React from 'react';
import { NavLink } from 'react-router-dom';

// material UI
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import TodayIcon from '@material-ui/icons/Today';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import BookmarkIcon from '@material-ui/icons/Bookmark';

// function
import { convertToJapTime, isObjEmpty } from '../../utility/Share/utilFunc';
import { config } from './../../config';

// scss
import classes from './PlaceCard.module.scss';

const placeCard = (props) => {
  const {
    bookmarkClickHandler,
    bookmarkClicked,
    likeClickHandler,
    likeClicked,
    card,
    user,
  } = props;

  // user認証有り　bookmarkIconを表示させる
  const bookmarkIcon = isObjEmpty(user) ? (
    ''
  ) : (
    <button
      onClick={() => bookmarkClickHandler(card.id)}
      className={classes.reset_btn}
    >
      {bookmarkClicked[card.id] ? (
        <BookmarkIcon
          className={`${classes.card__icon} ${classes.bookmark} ${classes.bookmark_filled}`}
        />
      ) : (
        <BookmarkBorderIcon
          className={`${classes.card__icon} ${classes.bookmark}`}
        />
      )}
    </button>
  );

  // like
  const likeIcon = (
    <button
      onClick={() => likeClickHandler(card.id)}
      className={classes.reset_btn}
    >
      {likeClicked[card.id].liked ? (
        <FavoriteIcon
          className={`${classes.card__icon} ${classes.heart} ${classes.heart_filled}`}
        />
      ) : (
        <FavoriteBorderIcon
          className={`${classes.card__icon} ${classes.heart}`}
        />
      )}
    </button>
  );

  return (
    <div className={`${classes.card} ${classes.linkbox}`}>
      <NavLink className={classes.nav_link} to={`/place/${card.id}`} />
      <div className={classes.card__header}>
        <div className={classes.card__picture}>
          <img
            src={`${config.s3.URL}/places/${card.images[0]}`}
            alt={`${card.images[0]}`}
            className={classes.card__picture_top_image}
          />
        </div>
      </div>
      <div className={classes.card__details}>
        <h3 className={classes.card__sub_heading}>{card.name}</h3>
        <p>{card.location.address}</p>
        <div className={classes.card__data} data-place-id={card.id}>
          {likeIcon}
          <span className={classes.mr_10}>{likeClicked[card.id].count}</span>
          {bookmarkIcon}
          <ChatBubbleOutlineIcon className={classes.card__icon} />
          <span>{card.comments.length}</span>
        </div>
        <div className={classes.card__data_grid}>
          {card.genre ? (
            <span className={classes.btn_genre}>#{card.genre}</span>
          ) : (
            ''
          )}
        </div>
        <div className={classes.card__data}>
          <PersonOutlineIcon className={classes.card__icon} />
          <span className={classes.mr_10}>{card.user.name}</span>
          <TodayIcon className={classes.card__icon} />
          <span>{convertToJapTime(card.id)}</span>
        </div>
      </div>
    </div>
  );
};

export default placeCard;
