import moment from 'moment-timezone';
import axios from './../../axios-instance';
import * as jpPrefecture from 'jp-prefecture';

// MongoDB ObjectId → Timestamp Converter
const dateFromObjectId = (objectId) =>
  new Date(parseInt(objectId.substring(0, 8), 16) * 1000);

export const convertToJapTime = (id) => {
  // グリニッジ標準時を取得
  const date = dateFromObjectId(id);
  // 日本時間にフォーマット
  return moment(date).tz('Asia/Tokyo').format('YYYY/MM/DD HH:mm');
};

export const arrayChunk = ([...array], size = 1) => {
  return array.reduce(
    (acc, value, index) =>
      index % size ? acc : [...acc, array.slice(index, index + size)],
    []
  );
};

export const isObjEmpty = (obj) => !Object.keys(obj).length;

// 文字列を判定
export const parseStrToBoolean = (str) => (str === 'true' ? true : false);

export const placeOpenArray = (arr, placeIndex) => {
  const copyArr = [...arr];
  copyArr.fill(false);
  const flg = !arr[placeIndex];
  copyArr[placeIndex] = flg;
  return copyArr;
};

export const categoryPlaceOpenArray = (arr, categoryIndex, placeIndex) => {
  const copyArr = [...Array(arr.length)].map((_, i) =>
    Array(arr[i].length).fill(false)
  );
  const flg = !arr[categoryIndex][placeIndex];
  copyArr[categoryIndex][placeIndex] = flg;
  return copyArr;
};

// 多次元配列
// [[...], [...], [...]]
// Array(0).fill(false) -> []
export const initCategoryPlaceOpenArray = (arr, defaultValue) => {
  const boxArrCategory = Array(arr.length);
  arr.forEach(
    (c, i) => (boxArrCategory[i] = Array(c.place_id.length).fill(defaultValue))
  );
  return boxArrCategory;
};

// 画像の読み込み
export const readerOnloaded = (data) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader);
    reader.readAsDataURL(data);
  });

export const contactMail = async (data) => {
  try {
    const response = await axios.post('/api/v1/user/contact', data);

    if (response.status === 204) {
      return true;
    } else {
      throw new Error('error');
    }
  } catch (err) {
    throw err;
  }
};

export const contactImage = async (data) => {
  try {
    const url = '/api/v1/image';

    const res = await axios({
      method: 'POST',
      url,
      data,
    });

    if (res.status !== 200) throw new Error('error');

    return res.data.data.data;
  } catch (err) {
    throw err;
  }
};

export const toBlob = (base64) => {
  const ext = base64.split('/')[1].split(';')[0];
  const bin = atob(base64.replace(/^.*,/, ''));
  const buffer = new Uint8Array(bin.length);
  for (let i = 0; i < bin.length; i++) {
    buffer[i] = bin.charCodeAt(i);
  }
  // Blobを作成
  let blob;
  try {
    blob = new Blob([buffer.buffer], {
      type: `image/${ext}`,
    });
  } catch (e) {
    return false;
  }
  return blob;
};

export const contactUpdatePass = async (data) => {
  try {
    const url = '/api/v1/user/updatePassword';

    const res = await axios({
      method: 'PATCH',
      url,
      data,
    });

    if (res.data.status !== 'success') throw new Error('error');

    return;
  } catch (err) {
    throw err;
  }
};

// flag 1: 作成日順
// flag 2: いいね順
// flag 3: コメント順
export const sortPlace = (obj, flag) => {
  if (flag === 1) {
    obj.sort((a, b) => {
      if (dateFromObjectId(a.id) > dateFromObjectId(b.id)) {
        return -1;
      } else {
        return 1;
      }
    });
  }
  if (flag === 2) {
    obj.sort((a, b) => {
      if (a.likes.length > b.likes.length) {
        return -1;
      } else {
        return 1;
      }
    });
  }
  if (flag === 3) {
    obj.sort((a, b) => {
      if (a.comments.length > b.comments.length) {
        return -1;
      } else {
        return 1;
      }
    });
  }

  return obj;
};

export const searchNameGenre = (arrObj, keyword) => {
  const resultObj = [];

  arrObj.forEach((p) => {
    // 名前の確認
    if (p.name.indexOf(keyword) !== -1) {
      resultObj.push(p);

      // ジャンルの確認
    } else if (p.genre !== undefined && p.genre.indexOf(keyword) !== -1) {
      resultObj.push(p);
    }
  });

  return resultObj;
};

// 都道府県　ローマ字→日本語
// ["niigata", "aichi", "osaka", "shimane"]
// ["新潟県", "愛知県", "大阪府", "島根県"]
export const convertEnToJa = (data) =>
  data.map((d) => jpPrefecture.findBy('pref', 'en', d, ['name']).name);

export const searchPrefecturesPlaces = (arrObj, prefectures) => {
  const resultObj = [];

  // モーダルで選択された都道府県に住所が一致するお気に入りの場所を取得
  prefectures.forEach((p) => {
    // 正規表現オブジェクト 正規表現の内容に変数を使用できる
    // ex) /^静岡県/
    const regex = new RegExp(`^${p}`);

    const searchPrefectures = (obj) => {
      // 返り値が0のplaceを取得
      obj.forEach((o) => {
        // 正規表現　先頭から指定の文字が完全一致したplaceを判定
        // 0: 正規表現が一致
        const flag = o.location.address.search(regex);

        // 正規表現が一致したplaceをstate.search.resultsにpush
        if (flag === 0) {
          resultObj.push(o);
        }
      });
    };

    searchPrefectures(arrObj);
  });

  return resultObj;
};
