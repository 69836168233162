import axios from "axios";
import { config } from "./config";

axios.defaults.withCredentials = true;

const instance = axios.create({
  baseURL: config.restful.apiURL,
});

export default instance;
