import * as actionTypes from './../actions/actionTypes';
import updateObject from './../../utility/Share/updateObject';

const initialState = {
  user: {},
  userMsg: {
    add: null,
    delete: null,
    login: null,
  },
  placeDataMsg: {
    delete: null,
  },
};

const reducer = (state = initialState, action) => {
  if (
    action.type === actionTypes.GET_USER ||
    action.type === actionTypes.LOGOUT_USER
  ) {
    return updateObject(state, {
      user: action.user,
    });
  }

  if (action.type === actionTypes.UPDATE_ME) {
    return {
      ...state,
      user: {
        ...state.user,
        name: action.user.name,
        photo: action.user.photo,
      },
    };
  }

  if (action.type === actionTypes.CREATE_CATEGORY) {
    return {
      ...state,
      user: {
        ...state.user,
        categories: [...state.user.categories, action.category],
      },
    };
  }

  if (
    action.type === actionTypes.EDIT_CATEGORY_NAME ||
    action.type === actionTypes.EDIT_CATEGORY_PLACE
  ) {
    // 対象のカテゴリのデータを差し替える
    return {
      ...state,
      user: {
        ...state.user,
        categories: [...state.user.categories].map((category) => {
          if (category._id === action.category._id) {
            return action.category;
          } else {
            return category;
          }
        }),
      },
    };
  }

  if (action.type === actionTypes.DELETE_CATEGORY) {
    return {
      ...state,
      user: {
        ...state.user,
        categories: [...state.user.categories].filter(
          (c) => c._id !== action.categoryId
        ),
      },
    };
  }

  if (action.type === actionTypes.DELETE_CATEGORY_PLACE) {
    return {
      ...state,
      user: {
        ...state.user,
        categories: [...state.user.categories].map((category) => {
          if (category._id === action.categoryId) {
            return {
              ...category,
              place_id: [...category.place_id].filter(
                (p) => p.id !== action.placeId
              ),
            };
          } else {
            return category;
          }
        }),
      },
    };
  }

  if (action.type === actionTypes.DELETE_CATEGORY_SELF_PLACE) {
    return {
      ...state,
      user: {
        ...state.user,
        categories: [...state.user.categories].map((category) => {
          return {
            ...category,
            place_id: category.place_id.filter(
              (place) => place.id !== action.placeId
            ),
          };
        }),
      },
    };
  }

  if (action.type === actionTypes.ADD_BOOKMARK) {
    return {
      ...state,
      userMsg: {
        ...state.userMsg,
        add: action.status,
      },
      user: {
        ...state.user,
        bookmarks: state.user.bookmarks.concat(action.bookmark),
      },
    };
  }

  if (action.type === actionTypes.DELETE_BOOKMARK) {
    return {
      ...state,
      userMsg: {
        ...state.userMsg,
        delete: action.status,
      },
      user: {
        ...state.user,
        bookmarks: state.user.bookmarks.filter(
          (b) => b.place_id !== action.placeId
        ),
      },
    };
  }

  if (action.type === actionTypes.ERROR_GET_USER) {
    return {
      ...state,
      userMsg: {
        ...state.userMsg,
        login: action.error,
      },
    };
  }

  if (action.type === actionTypes.ERROR_ADD_BOOKMARK) {
    return {
      ...state,
      userMsg: {
        ...state.userMsg,
        add: action.status,
      },
    };
  }

  if (action.type === actionTypes.ERROR_DELETE_BOOKMARK) {
    return {
      ...state,
      userMsg: {
        ...state.userMsg,
        delete: action.status,
      },
    };
  }

  if (action.type === actionTypes.RESET_BOOKMARK) {
    return {
      ...state,
      userMsg: {
        ...state.userMsg,
        add: action.status,
        delete: action.status,
      },
    };
  }

  if (action.type === actionTypes.RESET_ERROR_MSG) {
    return {
      ...state,
      userMsg: {
        ...state.userMsg,
        login: action.error,
      },
    };
  }

  if (action.type === actionTypes.DELETE_BOOKMARK_PLACE) {
    return {
      ...state,
      // 対象のお気に入りの場所をplaceから削除する
      user: {
        ...state.user,
        bookmarks: [...state.user.bookmarks].filter(
          (b) => b.place_id !== action.placeId
        ),
      },
      placeDataMsg: {
        ...state.placeDataMsg,
        delete: action.status,
      },
    };
  }

  if (action.type === actionTypes.ERROR_DELETE_BOOKMARK_PLACE) {
    return {
      ...state,
      placeDataMsg: {
        delete: action.status,
      },
    };
  }

  if (action.type === actionTypes.RESET_DELETE_BOOKMARK) {
    return {
      ...state,
      placeDataMsg: {
        delete: action.status,
      },
    };
  }

  return state;
};

export default reducer;
