import React from "react";

import classes from "./Input.module.scss";

const input = (props) => {
  let inputEl = null;
  const inputClasses = [classes.form__input];

  if (props.userInfoStyle) {
    inputClasses.push(classes.user_info_input);
  }

  switch (props.elementType) {
    case "input":
      inputEl = (
        <input
          className={inputClasses.join(" ")}
          {...props.elementConfig}
          required={props.required || props.requiredSpan ? "required" : ""}
          defaultValue={props.value}
          onChange={props.changed}
          ref={props.refProp}
        />
      );
      break;
    case "textarea":
      inputEl = (
        <textarea
          className={inputClasses.join(" ")}
          {...props.elementConfig}
          required={props.required || props.requiredSpan ? "required" : ""}
          defaultValue={props.value}
          onChange={props.changed}
          ref={props.refProp}
        />
      );
      break;
    case "select":
      inputEl = (
        <select
          className={inputClasses.join(" ")}
          value={props.value}
          onChange={props.changed}
        >
          {props.elementConfig.options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.displayValue}
            </option>
          ))}
        </select>
      );
      break;
    default:
      inputEl = (
        <input
          className={inputClasses.join(" ")}
          {...props.elementConfig}
          value={props.value}
          onChange={props.changed}
        />
      );
  }

  // required span
  let spanEl = null;
  if (props.requiredSpan) {
    spanEl = <span className={classes.required}>※</span>;
  }

  return (
    <div className={classes.form__group}>
      <label htmlFor={props.htmlFor} className={classes.form__label}>
        {props.label}
      </label>
      {spanEl}
      {inputEl}
    </div>
  );
};

export default input;
