import React from "react";

import Auxiliary from "./../../../hoc/Auxiliary/Auxiliary";
import Button from "./../../UI/Button/Button";

import classes from "./PrefectureModal.module.scss";

const prefectureModal = (props) => {
  // フォームの有無
  let formClasses = [classes.prefectures_window, classes.hidden];
  let overlayClasses = [classes.overlay, classes.hidden];

  if (props.open) {
    formClasses = [classes.prefectures_window];
    overlayClasses = [classes.overlay];
  }
  return (
    <Auxiliary>
      <div className={overlayClasses.join(" ")} onClick={props.closed}></div>
      <div className={formClasses.join(" ")}>
        <button
          className={classes.btn__close_modal_prefectures}
          onClick={props.closed}
        >
          x
        </button>
        <form onSubmit={props.submit}>
          <div className={classes.p_modal_content}>
            <div className={classes.p_modal_body}>
              <div>
                <div className={classes.p_filterModalItem}>
                  <h3 className={classes.p_filterModalItem_headline}>
                    北海道・東北
                  </h3>
                  <div className={classes.p_filterModalItem_body}>
                    <div className={classes.c_formCheckbox}>
                      <input
                        className={classes.js_checkbox}
                        id="lb_area_01"
                        name="prefecture"
                        onChange={props.change}
                        type="checkbox"
                        value="hokkaido"
                      />
                      <label htmlFor="lb_area_01">北海道</label>
                    </div>
                    <div className={classes.c_formCheckbox}>
                      <input
                        className={classes.js_checkbox}
                        id="lb_area_02"
                        name="prefecture"
                        onChange={props.change}
                        type="checkbox"
                        value="aomori"
                      />
                      <label htmlFor="lb_area_02">青森県</label>
                    </div>
                    <div className={classes.c_formCheckbox}>
                      <input
                        className={classes.js_checkbox}
                        id="lb_area_03"
                        name="prefecture"
                        onChange={props.change}
                        type="checkbox"
                        value="iwate"
                      />
                      <label htmlFor="lb_area_03">岩手県</label>
                    </div>
                    <div className={classes.c_formCheckbox}>
                      <input
                        className={classes.js_checkbox}
                        id="lb_area_04"
                        name="prefecture"
                        onChange={props.change}
                        type="checkbox"
                        value="miyagi"
                      />
                      <label htmlFor="lb_area_04">宮城県</label>
                    </div>
                    <div className={classes.c_formCheckbox}>
                      <input
                        className={classes.js_checkbox}
                        id="lb_area_05"
                        name="prefecture"
                        onChange={props.change}
                        type="checkbox"
                        value="akita"
                      />
                      <label htmlFor="lb_area_05">秋田県</label>
                    </div>
                    <div className={classes.c_formCheckbox}>
                      <input
                        className={classes.js_checkbox}
                        id="lb_area_06"
                        name="prefecture"
                        onChange={props.change}
                        type="checkbox"
                        value="yamagata"
                      />
                      <label htmlFor="lb_area_06">山形県</label>
                    </div>
                    <div className={classes.c_formCheckbox}>
                      <input
                        className={classes.js_checkbox}
                        id="lb_area_07"
                        name="prefecture"
                        onChange={props.change}
                        type="checkbox"
                        value="fukushima"
                      />
                      <label htmlFor="lb_area_07">福島県</label>
                    </div>
                  </div>
                </div>
                <div className={classes.p_filterModalItem}>
                  <h3 className={classes.p_filterModalItem_headline}>関東</h3>
                  <div className={classes.p_filterModalItem_body}>
                    <div className={classes.c_formCheckbox}>
                      <input
                        className={classes.js_checkbox}
                        id="lb_area_08"
                        name="prefecture"
                        onChange={props.change}
                        type="checkbox"
                        value="ibaraki"
                      />
                      <label htmlFor="lb_area_08">茨城県</label>
                    </div>
                    <div className={classes.c_formCheckbox}>
                      <input
                        className={classes.js_checkbox}
                        id="lb_area_09"
                        name="prefecture"
                        onChange={props.change}
                        type="checkbox"
                        value="tochigi"
                      />
                      <label htmlFor="lb_area_09">栃木県</label>
                    </div>
                    <div className={classes.c_formCheckbox}>
                      <input
                        className={classes.js_checkbox}
                        id="lb_area_10"
                        name="prefecture"
                        onChange={props.change}
                        type="checkbox"
                        value="gunma"
                      />
                      <label htmlFor="lb_area_10">群馬県</label>
                    </div>
                    <div className={classes.c_formCheckbox}>
                      <input
                        className={classes.js_checkbox}
                        id="lb_area_11"
                        name="prefecture"
                        onChange={props.change}
                        type="checkbox"
                        value="saitama"
                      />
                      <label htmlFor="lb_area_11">埼玉県</label>
                    </div>
                    <div className={classes.c_formCheckbox}>
                      <input
                        className={classes.js_checkbox}
                        id="lb_area_12"
                        name="prefecture"
                        onChange={props.change}
                        type="checkbox"
                        value="chiba"
                      />
                      <label htmlFor="lb_area_12">千葉県</label>
                    </div>
                    <div className={classes.c_formCheckbox}>
                      <input
                        className={classes.js_checkbox}
                        id="lb_area_13"
                        name="prefecture"
                        onChange={props.change}
                        type="checkbox"
                        value="tokyo"
                      />
                      <label htmlFor="lb_area_13">東京都</label>
                    </div>
                    <div className={classes.c_formCheckbox}>
                      <input
                        className={classes.js_checkbox}
                        id="lb_area_14"
                        name="prefecture"
                        onChange={props.change}
                        type="checkbox"
                        value="kanagawa"
                      />
                      <label htmlFor="lb_area_14">神奈川県</label>
                    </div>
                  </div>
                </div>
                <div className={classes.p_filterModalItem}>
                  <h3 className={classes.p_filterModalItem_headline}>
                    北陸・甲信越
                  </h3>
                  <div className={classes.p_filterModalItem_body}>
                    <div className={classes.c_formCheckbox}>
                      <input
                        className={classes.js_checkbox}
                        id="lb_area_15"
                        name="prefecture"
                        onChange={props.change}
                        type="checkbox"
                        value="niigata"
                      />
                      <label htmlFor="lb_area_15">新潟県</label>
                    </div>
                    <div className={classes.c_formCheckbox}>
                      <input
                        className={classes.js_checkbox}
                        id="lb_area_16"
                        name="prefecture"
                        onChange={props.change}
                        type="checkbox"
                        value="toyama"
                      />
                      <label htmlFor="lb_area_16">富山県</label>
                    </div>
                    <div className={classes.c_formCheckbox}>
                      <input
                        className={classes.js_checkbox}
                        id="lb_area_17"
                        name="prefecture"
                        onChange={props.change}
                        type="checkbox"
                        value="ishikawa"
                      />
                      <label htmlFor="lb_area_17">石川県</label>
                    </div>
                    <div className={classes.c_formCheckbox}>
                      <input
                        className={classes.js_checkbox}
                        id="lb_area_18"
                        name="prefecture"
                        onChange={props.change}
                        type="checkbox"
                        value="fukui"
                      />
                      <label htmlFor="lb_area_18">福井県</label>
                    </div>
                    <div className={classes.c_formCheckbox}>
                      <input
                        className={classes.js_checkbox}
                        id="lb_area_19"
                        name="prefecture"
                        onChange={props.change}
                        type="checkbox"
                        value="yamanashi"
                      />
                      <label htmlFor="lb_area_19">山梨県</label>
                    </div>
                    <div className={classes.c_formCheckbox}>
                      <input
                        className={classes.js_checkbox}
                        id="lb_area_20"
                        name="prefecture"
                        onChange={props.change}
                        type="checkbox"
                        value="nagano"
                      />
                      <label htmlFor="lb_area_20">長野県</label>
                    </div>
                  </div>
                </div>
                <div className={classes.p_filterModalItem}>
                  <h3 className={classes.p_filterModalItem_headline}>東海</h3>
                  <div className={classes.p_filterModalItem_body}>
                    <div className={classes.c_formCheckbox}>
                      <input
                        className={classes.js_checkbox}
                        id="lb_area_21"
                        name="prefecture"
                        onChange={props.change}
                        type="checkbox"
                        value="gifu"
                      />
                      <label htmlFor="lb_area_21">岐阜県</label>
                    </div>
                    <div className={classes.c_formCheckbox}>
                      <input
                        className={classes.js_checkbox}
                        id="lb_area_22"
                        name="prefecture"
                        onChange={props.change}
                        type="checkbox"
                        value="shizuoka"
                      />
                      <label htmlFor="lb_area_22">静岡県</label>
                    </div>
                    <div className={classes.c_formCheckbox}>
                      <input
                        className={classes.js_checkbox}
                        id="lb_area_23"
                        name="prefecture"
                        onChange={props.change}
                        type="checkbox"
                        value="aichi"
                      />
                      <label htmlFor="lb_area_23">愛知県</label>
                    </div>
                    <div className={classes.c_formCheckbox}>
                      <input
                        className={classes.js_checkbox}
                        id="lb_area_24"
                        name="prefecture"
                        onChange={props.change}
                        type="checkbox"
                        value="mie"
                      />
                      <label htmlFor="lb_area_24">三重県</label>
                    </div>
                  </div>
                </div>
                <div className={classes.p_filterModalItem}>
                  <h3 className={classes.p_filterModalItem_headline}>近畿</h3>
                  <div className={classes.p_filterModalItem_body}>
                    <div className={classes.c_formCheckbox}>
                      <input
                        className={classes.js_checkbox}
                        id="lb_area_25"
                        name="prefecture"
                        onChange={props.change}
                        type="checkbox"
                        value="shiga"
                      />
                      <label htmlFor="lb_area_25">滋賀県</label>
                    </div>
                    <div className={classes.c_formCheckbox}>
                      <input
                        className={classes.js_checkbox}
                        id="lb_area_26"
                        name="prefecture"
                        onChange={props.change}
                        type="checkbox"
                        value="kyoto"
                      />
                      <label htmlFor="lb_area_26">京都府</label>
                    </div>
                    <div className={classes.c_formCheckbox}>
                      <input
                        className={classes.js_checkbox}
                        id="lb_area_27"
                        name="prefecture"
                        onChange={props.change}
                        type="checkbox"
                        value="osaka"
                      />
                      <label htmlFor="lb_area_27">大阪府</label>
                    </div>
                    <div className={classes.c_formCheckbox}>
                      <input
                        className={classes.js_checkbox}
                        id="lb_area_28"
                        name="prefecture"
                        onChange={props.change}
                        type="checkbox"
                        value="hyogo"
                      />
                      <label htmlFor="lb_area_28">兵庫県</label>
                    </div>
                    <div className={classes.c_formCheckbox}>
                      <input
                        className={classes.js_checkbox}
                        id="lb_area_29"
                        name="prefecture"
                        onChange={props.change}
                        type="checkbox"
                        value="nara"
                      />
                      <label htmlFor="lb_area_29">奈良県</label>
                    </div>
                    <div className={classes.c_formCheckbox}>
                      <input
                        className={classes.js_checkbox}
                        id="lb_area_30"
                        name="prefecture"
                        onChange={props.change}
                        type="checkbox"
                        value="wakayama"
                      />
                      <label htmlFor="lb_area_30">和歌山県</label>
                    </div>
                  </div>
                </div>
                <div className={classes.p_filterModalItem}>
                  <h3 className={classes.p_filterModalItem_headline}>
                    中国・四国
                  </h3>
                  <div className={classes.p_filterModalItem_body}>
                    <div className={classes.c_formCheckbox}>
                      <input
                        className={classes.js_checkbox}
                        id="lb_area_31"
                        name="prefecture"
                        onChange={props.change}
                        type="checkbox"
                        value="tottori"
                      />
                      <label htmlFor="lb_area_31">鳥取県</label>
                    </div>
                    <div className={classes.c_formCheckbox}>
                      <input
                        className={classes.js_checkbox}
                        id="lb_area_32"
                        name="prefecture"
                        onChange={props.change}
                        type="checkbox"
                        value="shimane"
                      />
                      <label htmlFor="lb_area_32">島根県</label>
                    </div>
                    <div className={classes.c_formCheckbox}>
                      <input
                        className={classes.js_checkbox}
                        id="lb_area_33"
                        name="prefecture"
                        onChange={props.change}
                        type="checkbox"
                        value="okayama"
                      />
                      <label htmlFor="lb_area_33">岡山県</label>
                    </div>
                    <div className={classes.c_formCheckbox}>
                      <input
                        className={classes.js_checkbox}
                        id="lb_area_34"
                        name="prefecture"
                        onChange={props.change}
                        type="checkbox"
                        value="hiroshima"
                      />
                      <label htmlFor="lb_area_34">広島県</label>
                    </div>
                    <div className={classes.c_formCheckbox}>
                      <input
                        className={classes.js_checkbox}
                        id="lb_area_35"
                        name="prefecture"
                        onChange={props.change}
                        type="checkbox"
                        value="yamaguchi"
                      />
                      <label htmlFor="lb_area_35">山口県</label>
                    </div>
                    <div className={classes.c_formCheckbox}>
                      <input
                        className={classes.js_checkbox}
                        id="lb_area_36"
                        name="prefecture"
                        onChange={props.change}
                        type="checkbox"
                        value="tokushima"
                      />
                      <label htmlFor="lb_area_36">徳島県</label>
                    </div>
                    <div className={classes.c_formCheckbox}>
                      <input
                        className={classes.js_checkbox}
                        id="lb_area_37"
                        name="prefecture"
                        onChange={props.change}
                        type="checkbox"
                        value="kagawa"
                      />
                      <label htmlFor="lb_area_37">香川県</label>
                    </div>
                    <div className={classes.c_formCheckbox}>
                      <input
                        className={classes.js_checkbox}
                        id="lb_area_38"
                        name="prefecture"
                        onChange={props.change}
                        type="checkbox"
                        value="ehime"
                      />
                      <label htmlFor="lb_area_38">愛媛県</label>
                    </div>
                    <div className={classes.c_formCheckbox}>
                      <input
                        className={classes.js_checkbox}
                        id="lb_area_39"
                        name="prefecture"
                        onChange={props.change}
                        type="checkbox"
                        value="kochi"
                      />
                      <label htmlFor="lb_area_39">高知県</label>
                    </div>
                  </div>
                </div>
                <div className={classes.p_filterModalItem}>
                  <h3 className={classes.p_filterModalItem_headline}>
                    九州・沖縄
                  </h3>
                  <div className={classes.p_filterModalItem_body}>
                    <div className={classes.c_formCheckbox}>
                      <input
                        className={classes.js_checkbox}
                        id="lb_area_40"
                        name="prefecture"
                        onChange={props.change}
                        type="checkbox"
                        value="fukuoka"
                      />
                      <label htmlFor="lb_area_40">福岡県</label>
                    </div>
                    <div className={classes.c_formCheckbox}>
                      <input
                        className={classes.js_checkbox}
                        id="lb_area_41"
                        name="prefecture"
                        onChange={props.change}
                        type="checkbox"
                        value="saga"
                      />
                      <label htmlFor="lb_area_41">佐賀県</label>
                    </div>
                    <div className={classes.c_formCheckbox}>
                      <input
                        className={classes.js_checkbox}
                        id="lb_area_42"
                        name="prefecture"
                        onChange={props.change}
                        type="checkbox"
                        value="nagasaki"
                      />
                      <label htmlFor="lb_area_42">長崎県</label>
                    </div>
                    <div className={classes.c_formCheckbox}>
                      <input
                        className={classes.js_checkbox}
                        id="lb_area_43"
                        name="prefecture"
                        onChange={props.change}
                        type="checkbox"
                        value="kumamoto"
                      />
                      <label htmlFor="lb_area_43">熊本県</label>
                    </div>
                    <div className={classes.c_formCheckbox}>
                      <input
                        className={classes.js_checkbox}
                        id="lb_area_44"
                        name="prefecture"
                        onChange={props.change}
                        type="checkbox"
                        value="oita"
                      />
                      <label htmlFor="lb_area_44">大分県</label>
                    </div>
                    <div className={classes.c_formCheckbox}>
                      <input
                        className={classes.js_checkbox}
                        id="lb_area_45"
                        name="prefecture"
                        onChange={props.change}
                        type="checkbox"
                        value="miyazaki"
                      />
                      <label htmlFor="lb_area_45">宮崎県</label>
                    </div>
                    <div className={classes.c_formCheckbox}>
                      <input
                        className={classes.js_checkbox}
                        id="lb_area_46"
                        name="prefecture"
                        onChange={props.change}
                        type="checkbox"
                        value="kagoshima"
                      />
                      <label htmlFor="lb_area_46">鹿児島県</label>
                    </div>
                    <div className={classes.c_formCheckbox}>
                      <input
                        className={classes.js_checkbox}
                        id="lb_area_47"
                        name="prefecture"
                        onChange={props.change}
                        type="checkbox"
                        value="okinawa"
                      />
                      <label htmlFor="lb_area_47">沖縄県</label>
                    </div>
                  </div>
                </div>
              </div>
              <Button btnType="success" styleType="c_button">
                このエリアで絞り込む
              </Button>
            </div>
          </div>
        </form>
      </div>
    </Auxiliary>
  );
};

export default prefectureModal;
