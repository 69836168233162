import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withAlert } from "react-alert";

import ImageSlider from "../../components/Slider/Slider";
import LeafletMap from "../../components/Leaflet/PlaceLeaflet/PlaceLeaflet";
import Button from "./../../components/UI/Button/Button";
import Hotel from "./../../components/PlaceCard/Hotel/Hotel";
import Comment from "./../../components/Comment/Comment";

import RoomIcon from "@material-ui/icons/Room";

import classes from "./Place.module.scss";

import Spinner from "./../../components/UI/Spinner/Spinner";

import * as actionCreators from "./../../store/actions/index";
import { isObjEmpty } from "./../../utility/Share/utilFunc";

class Place extends Component {
  submitHandler = async (event, paramId) => {
    event.preventDefault();

    const alert = this.props.alert;

    const data = {
      comment: event.target[0].value,
      place_id: paramId,
    };

    // 認証あり
    if (!isObjEmpty(this.props.user)) {
      data.user_id = this.props.user.id;
    }

    try {
      await this.props.onCreateComment(data);

      alert.success("コメントを投稿しました");
    } catch (error) {
      alert.error("コメントの投稿に失敗しました");
    }

    // コメント欄を削除
    event.target[0].value = "";
  };

  render() {
    let userName;
    // 認証あり
    if (!isObjEmpty(this.props.user)) {
      userName = this.props.user.name;
    }

    // /place/:id idをもとにデータを取得
    const paramId = this.props.match.params.id;
    let placeData;
    if (this.props.place.length !== 0) {
      placeData = this.props.place.find((p) => p.id === paramId);

      return (
        <div>
          <div className={classes.subject_container}>
            <h2 className={classes.title_text}>{placeData.name}</h2>
          </div>
          <div className={classes.place_container}>
            <ImageSlider placeImages={placeData.images} />
            <div className={`${classes.place} ${classes.place__map}`}>
              {placeData.location.coordinates !== null ? (
                <LeafletMap
                  coordinates={placeData.location.coordinates}
                  name={placeData.name}
                  hotels={placeData.hotels}
                />
              ) : (
                ""
              )}
            </div>
            <div className={classes.place}>
              <h3 className={classes.address_text}>
                住所 {placeData.location.address}
              </h3>
              <h3 className={classes.memo_text}>メモ</h3>
              <div className={classes.memo_wrapper}>
                <span>{placeData.memo}</span>
              </div>
            </div>
            <div className={`${classes.place} ${classes.map}`}>
              <p>
                <RoomIcon className={classes.pin_icon_1} />
                <span>: お気に入りの場所</span>
                <RoomIcon className={classes.pin_icon_2} />
                <span>: 宿泊施設</span>
              </p>
              <p className={classes.annotation_text}>
                ※こちらのピンは正確な位置が反映されてない場合があります。ご了承ください
              </p>
            </div>
            <div
              className={`${classes.place} ${classes.hotel__card_container}`}
            >
              <h3 className={classes.memo_text}>
                お気に入り場所近くの宿泊施設
              </h3>
              <div className={classes.hotel__card_wrapper}>
                {placeData.hotels.map((h, i) => (
                  <Hotel hotel={h} key={i + 1} />
                ))}
              </div>
              {/* eslint-disable-next-line */}
              <a href="https://webservice.rakuten.co.jp/" target="_blank">
                <img
                  src="https://webservice.rakuten.co.jp/img/credit/200709/credit_22121.gif"
                  border="0"
                  alt="楽天ウェブサービスセンター"
                  title="楽天ウェブサービスセンター"
                  width="221"
                  height="21"
                />
              </a>
            </div>
            <div className={`${classes.place} ${classes.comment_container}`}>
              <h3 className={classes.memo_text}>コメント</h3>
              <div className={classes.form_comment}>
                <form onSubmit={(e) => this.submitHandler(e, paramId)}>
                  <div>
                    <textarea
                      className={classes.form__input_comment}
                      name="comment"
                      id="comment"
                      cols="33"
                      row="5"
                      placeholder="コメント"
                      required
                    ></textarea>
                  </div>
                  <Button btnType="success">送信</Button>
                </form>
              </div>
            </div>
            <div className={`${classes.place} ${classes.commentted_container}`}>
              <ul className={classes.comment_list}>
                {placeData.comments.length !== 0
                  ? placeData.comments.map((c, i) => (
                      <Comment
                        comment={c}
                        num={i}
                        key={i + 1}
                        name={userName}
                      />
                    ))
                  : ""}
              </ul>
            </div>
          </div>
        </div>
      );
    } else {
      // dispatch get place data
      this.props.onGetPlace(paramId);

      return (
        <div className="main-container">
          <Spinner />
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    place: state.place.places,
    commentStatus: state.place.commentMsg,
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCreateComment: (data) => dispatch(actionCreators.createComment(data)),
    onGetPlace: (placeId) => dispatch(actionCreators.getPlace(placeId)),
  };
};

Place = compose(
  withAlert(),
  connect(mapStateToProps, mapDispatchToProps)
)(Place);

export default Place;
