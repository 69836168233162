import React from "react";

import FooterLogo from "./../../Logo/FooterLogo/FooterLogo";
import FooterNavItems from "./../NavigationItems/FooterNavItems/FooterNavItems";
import "./Footer.scss";

const footer = (props) => (
  <footer className="footer">
    <FooterLogo />
    <FooterNavItems />
  </footer>
);

export default footer;
