import React, { Component } from "react";

import LocalPhoneIcon from "@material-ui/icons/LocalPhone";
import StarIcon from "@material-ui/icons/Star";

import classes from "./Hotel.module.scss";

class Hotel extends Component {
  createStar(reviewAverage) {
    if (!reviewAverage) {
      return;
    }

    return [1, 2, 3, 4, 5].map((star, i) => (
      <StarIcon
        key={i}
        className={reviewAverage >= star ? classes.yellow : classes.grey}
      />
    ));
  }

  render() {
    return (
      <div className={`${classes.hotel__card} ${classes.linkbox}`}>
        {/* eslint-disable-next-line */}
        <a
          href={this.props.hotel.hotelInformationUrl}
          target="_blank"
          rel="noopener noreferrer"
        ></a>
        <div className={classes.hotel__header}>
          <div className={classes.hotel__card__picture}>
            <img
              src={this.props.hotel.hotelImageUrl}
              alt={this.props.hotel.hotelName}
              className={classes.card__picture_img}
            />
          </div>
        </div>
        <div className={classes.hotel__card_details}>
          <h3 className={classes.card__sub_heading}>
            {this.props.hotel.hotelName}
          </h3>
          <div className={classes.hotel__card_data}>
            <span>
              〒{this.props.hotel.postalCode}　{this.props.hotel.address1}
              {this.props.hotel.address2}
            </span>
          </div>
          <div className={classes.hotel__card_data}>
            <LocalPhoneIcon />
            <span>{this.props.hotel.telephoneNo}</span>
          </div>
          <div className={classes.hotel__card_data}>
            <span>
              {this.props.hotel.hotelMinCharge
                ? `最小価格 ¥${this.props.hotel.hotelMinCharge}~`
                : ""}
            </span>
          </div>
          <div className={classes.hotel__card_data}>
            {this.createStar(this.props.hotel.reviewAverage)}
          </div>
          <div className={classes.hotel__card_data}>
            <span>
              レビュー数{" "}
              {this.props.hotel.reviewCount
                ? this.props.hotel.reviewCount
                : "0"}
              件
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default Hotel;
