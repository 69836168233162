// place
export const GET_ALL_PLACES = 'GET_ALL_PLACES';
export const SORT_PLACES_CREATE = 'SORT_PLACES_CREATE';
export const SORT_PLACES_LIKE = 'SORT_PLACES_LIKE';
export const SORT_PLACES_COMMENT = 'SORT_PLACES_COMMENT';
export const SEARCH_KEYWORD = 'SEARCH_KEYWORD';
export const PICK_PREFECTURES = 'PICK_PREFECTURES';
export const CREATE_PLACE = 'CREATE_PLACE';
export const GET_PLACE = 'GET_PLACE';
export const EDIT_PLACE = 'EDIT_PLACE';
export const DELETE_SELF_PLACE = 'DELETE_SELF_PLACE';
export const ERROR_DELETE_SELF_PLACE = 'ERROR_DELETE_SELF_PLACE';
export const RESET_DELETE_SELF_PLACE = 'RESET_DELETE_SELF_PLACE';

// bookmark place
export const DELETE_BOOKMARK_PLACE = 'DELETE_BOOKMARK_PLACE';
export const RESET_DELETE_BOOKMARK = 'RESET_DELETE_BOOKMARK';
export const ERROR_DELETE_BOOKMARK_PLACE = 'ERROR_DELETE_BOOKMARK_PLACE';

// auth
export const GET_USER = 'GET_USER';
export const ERROR_GET_USER = 'ERROR_GET_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const UPDATE_ME = 'UPDATE_ME';
export const RESET_ERROR_MSG = 'RESET_ERROR_MSG';

// bookmark
export const ADD_BOOKMARK = 'ADD_BOOKMARK';
export const DELETE_BOOKMARK = 'DELETE_BOOKMARK';
export const ERROR_ADD_BOOKMARK = 'ERROR_ADD_BOOKMARK';
export const ERROR_DELETE_BOOKMARK = 'ERROR_DELETE_BOOKMARK';
export const RESET_BOOKMARK = 'RESET_BOOKMARK';

// like
export const ADD_LIKE = 'ADD_LIKE';
export const DELETE_LIKE = 'DELETE_LIKE';
export const ERROR_ADD_LIKE = 'ERROR_ADD_LIKE';
export const ERROR_DELETE_LIKE = 'ERROR_DELETE_LIKE';
export const RESET_LIKE = 'RESET_LIKE';

// comment
export const CREATE_COMMENT = 'CREATE_COMMENT';

// category
export const CREATE_CATEGORY = 'CREATE_CATEGORY';
export const EDIT_CATEGORY_NAME = 'EDIT_CATEGORY_NAME';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const EDIT_CATEGORY_PLACE = 'EDIT_CATEGORY_PLACE';
export const DELETE_CATEGORY_PLACE = 'DELETE_CATEGORY_PLACE';
export const DELETE_CATEGORY_SELF_PLACE = 'DELETE_CATEGORY_SELF_PLACE';
