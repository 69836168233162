import React from "react";
import { NavLink } from "react-router-dom";

import placeShareLogo from "./../../../assets/images/place-share-logo.png";
import "./HeaderLogo.scss";

const headerLogo = (props) => (
  <nav>
    <NavLink to="/" className="HeaderLogo">
      <img
        src={placeShareLogo}
        alt="PlaceShareLogo"
        className="HeaderLogo_el"
      />
    </NavLink>
  </nav>
);

export default headerLogo;
