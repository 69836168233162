import React, { Component } from 'react';
import { connect } from 'react-redux';

import TabComponent from '../../components/UI/Tabs/TabComponent';
import AddPlaceForm from './../../components/Form/AddPlaceForm/AddPlaceForm';
import DeletePlaceForm from './../../components/Form/DeletePlaceForm/DeletePlaceForm';
import CategoryModal from './../../components/Form/CategoryModal/CategoryModal';

import { isObjEmpty } from './../../utility/Share/utilFunc';

import Spinner from './../../components/UI/Spinner/Spinner';

import * as actionCreators from './../../store/actions/index';

class Setting extends Component {
  state = {
    showAddPlaceForm: false,
    showDeletePlaceForm: false,
    showCategoryModal: false,
    place: {
      placeId: '',
      name: '',
      address: '',
      memo: '',
      grere: '',
      images: [],
    },
    deleteType: '',
    categoryType: '',
    categoryFlg: false,
    selectedCategory: {},
  };

  addFormOpenHandler = (event) => {
    const { placeId } = event.currentTarget.dataset;

    const placeData = this.props.place.find((p) => p.id === placeId);

    const { name, genre, memo, images } = placeData;
    const { address } = placeData.location;

    this.setState({
      showAddPlaceForm: true,
      place: {
        placeId,
        name,
        address,
        genre,
        memo,
        images,
      },
    });
  };

  deleteFormOpenHandler = (event) => {
    const { placeId, deleteType, categoryIndex } = event.currentTarget.dataset;

    if (categoryIndex) {
      const selectedCategory =
        this.props.auth.categories[Number(categoryIndex)];
      this.setState({ selectedCategory });
    }

    this.setState({
      showDeletePlaceForm: true,
      place: { ...this.state.place, placeId },
      deleteType,
    });
  };

  categoryModalOpenHandler = (e, type) => {
    let selectedCategory;
    if (type === 'edit' || type === 'delete') {
      // 選択されたカテゴリのデータを取得
      const { categoryIndex } = e.currentTarget.dataset;
      selectedCategory = this.props.auth.categories[Number(categoryIndex)];
    }

    this.setState({
      showCategoryModal: true,
      categoryType: type,
      selectedCategory,
    });
  };

  addFormClosedHandler = () => {
    this.setState({ showAddPlaceForm: false });
  };

  deleteFormClosedHandler = () => {
    this.setState({ showDeletePlaceForm: false });
  };

  categoryModalClosedHandler = () => {
    this.setState({ showCategoryModal: false });
  };

  categoryComponentHandler = () => {
    this.setState({ categoryFlg: !this.state.categoryFlg });
  };

  getSelfPlaces() {
    return this.props.place.filter((p) => p.user.id === this.props.auth.id);
  }

  getBookmarkPlaces() {
    const bookmarkPlaces = [];
    this.props.auth.bookmarks.forEach((b) => {
      const placeData = this.props.place.find((p) => b.place_id === p.id);
      if (placeData) {
        bookmarkPlaces.push(placeData);
      }
    });
    return bookmarkPlaces;
  }

  render() {
    if (isObjEmpty(this.props.auth)) {
      this.props.history.push('/');
    }

    if (this.props.place.length !== 0) {
      const selfPlaces = this.getSelfPlaces();
      const bookmarkPlaces = this.getBookmarkPlaces();

      return (
        <div>
          <TabComponent
            selfPlaces={selfPlaces}
            bookmarkPlaces={bookmarkPlaces}
            categoryPlaces={this.props.auth.categories}
            userName={this.props.auth.name}
            addClick={this.addFormOpenHandler}
            deleteClick={this.deleteFormOpenHandler}
            categoryClick={(e, type) => this.categoryModalOpenHandler(e, type)}
            categoryComponent={this.categoryComponentHandler}
            categoryFlg={this.state.categoryFlg}
            user={this.props.auth}
          />
          <AddPlaceForm
            open={this.state.showAddPlaceForm}
            closed={this.addFormClosedHandler}
            name={this.state.place.name}
            address={this.state.place.address}
            genre={this.state.place.genre}
            memo={this.state.place.memo}
            images={this.state.place.images}
            formType="edit"
            id={this.state.place.placeId}
          />
          <DeletePlaceForm
            open={this.state.showDeletePlaceForm}
            closed={this.deleteFormClosedHandler}
            id={this.state.place.placeId}
            type={this.state.deleteType}
            selectedCategory={this.state.selectedCategory}
          />
          <CategoryModal
            open={this.state.showCategoryModal}
            closed={this.categoryModalClosedHandler}
            user={this.props.auth}
            type={this.state.categoryType}
            selectedCategory={this.state.selectedCategory}
          />
        </div>
      );
    } else {
      this.props.onInitPlaces();

      return (
        <div className="main-container">
          <Spinner />
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    place: state.place.results,
    auth: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onInitPlaces: () => dispatch(actionCreators.initPlaces()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Setting);
