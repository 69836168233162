import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withAlert } from "react-alert";

import HeaderNaviItem from "./HeaderNavItem/HeaderNaviItem";
import { config } from "./../../../../config";

import classes from "./HeaderNavItems.module.scss";

import * as actionCreators from "./../../../../store/actions/index";

class HeaderNavItems extends Component {
  componentDidMount() {
    this.props.onInitAuth();
  }

  logoutHandler = async () => {
    const alert = this.props.alert;

    try {
      await this.props.onLogoutAuth();
      alert.success("ログアウトに成功しました");
    } catch (error) {
      alert.error("ログアウトに失敗しました");
    }
  };

  render() {
    return (
      <nav>
        {/* <nav className={classes.desktopOnly}> */}
        <ul className={classes.HeaderNavItems}>
          {Object.keys(this.props.user).length ? (
            <HeaderNaviItem link="/settings">
              <img
                className={classes.nav__user_img}
                src={`${config.s3.URL}/users/${this.props.user.photo}`}
                alt={this.props.user.name}
              />
              <span className={classes.nav__user_name}>
                {this.props.user.name}
              </span>
            </HeaderNaviItem>
          ) : (
            <HeaderNaviItem link="/login">ログイン</HeaderNaviItem>
          )}
          {Object.keys(this.props.user).length ? (
            <HeaderNaviItem link="/" clicked={this.logoutHandler}>
              ログアウト
            </HeaderNaviItem>
          ) : (
            <HeaderNaviItem link="/signup">アカウント作成</HeaderNaviItem>
          )}
        </ul>
      </nav>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLogoutAuth: () => dispatch(actionCreators.initLogoutAuth()),
    onInitAuth: () => dispatch(actionCreators.initAuth()),
  };
};

HeaderNavItems = compose(
  withAlert(),
  connect(mapStateToProps, mapDispatchToProps)
)(HeaderNavItems);

export default HeaderNavItems;
