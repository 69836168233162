import React, { Component } from "react";
import Leaflet from "leaflet";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "../../../utility/Leaflet/leaflet.sprite";

import "./PlaceLeaflet.scss";

class LeafletMap extends Component {
  createHotelMarker() {
    let hotelsMarker = [];

    const icon = Leaflet.spriteIcon("green");

    this.props.hotels.forEach((h) => {
      const coords = [h.latitude, h.longitude];

      hotelsMarker.push(
        <Marker position={coords} icon={icon} key={h.hotelName}>
          <Popup className="map__popup">
            <a
              className="place__name"
              href={h.hotelInformationUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {h.hotelName}
            </a>
          </Popup>
        </Marker>
      );
    });
    return hotelsMarker;
  }

  render() {
    Leaflet.Icon.Default.imagePath =
      "//cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/";

    const icon = Leaflet.spriteIcon("blue");
    const position = [...this.props.coordinates];

    return (
      <MapContainer center={position} zoom={16} scrollWheelZoom={true}>
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={position} icon={icon} key={this.props.name}>
          <Popup className="map__popup">
            <a
              className="place__name"
              href={`https://www.google.com/maps/dir/${this.props.name}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {this.props.name}
            </a>
          </Popup>
        </Marker>
        {this.props.hotels.length ? this.createHotelMarker() : ""}
      </MapContainer>
    );
  }
}

export default LeafletMap;
