import React from "react";

import HeaderLogo from "../../Logo/HeaderLogo/HeaderLogo";
import HeaderNavItems from "./../NavigationItems/HeaderNavItems/HeaderNavItems";
import MenuIcon from "./../../MenuIcon/MenuIcon";
import "./Header.scss";

const header = (props) => (
  <header className="header">
    <HeaderLogo />
    <div className="desktopOnly">
      <HeaderNavItems />
    </div>
    <MenuIcon opened={props.opened} />
  </header>
);

export default header;
