import React from "react";

import { convertToJapTime } from "./../../utility/Share/utilFunc";

import "./Comment.scss";

const comment = (props) => (
  <li key={props.num} className="comment__list">
    <span>
      {props.num + 1}.{" "}
      {props.comment.user_id
        ? props.comment.user_id.name === undefined
          ? props.name
          : props.comment.user_id.name
        : "No Name"}{" "}
      | {convertToJapTime(props.comment._id)}
    </span>
    <p className="comment-text">{props.comment.comment}</p>
  </li>
);

export default comment;
