import React from "react";
import { NavLink } from "react-router-dom";

import "./FooterNavItem.scss";

const footerNavItem = (props) => (
  <li className="FooterNavItem">
    <NavLink to={props.link}>{props.children}</NavLink>
  </li>
);

export default footerNavItem;
