import React from "react";

import classes from "./SideDrawer.module.scss";
import Auxiliary from "./../../../hoc/Auxiliary/Auxiliary";
import HeaderLogo from "./../../Logo/HeaderLogo/HeaderLogo";
import HeaderNavItems from "./../../Navigation/NavigationItems/HeaderNavItems/HeaderNavItems";

const sideDrawer = (props) => {
  let attachedClasses = [classes.SideDrawer, classes.Close];
  let overlayClasses = [classes.overlay, classes.hidden];

  if (props.open) {
    attachedClasses = [classes.SideDrawer, classes.Open];
    overlayClasses = [classes.overlay];
  }

  return (
    <Auxiliary>
      <div className={overlayClasses.join(" ")} onClick={props.closed}></div>
      <div className={attachedClasses.join(" ")} onClick={props.closed}>
        <HeaderLogo />
        <HeaderNavItems />
      </div>
    </Auxiliary>
  );
};

export default sideDrawer;
