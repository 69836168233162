import React from "react";

import FooterNavItem from "./FooterNavItem/FooterNavItem";

import "./FooterNavItems.scss";

const footerNavItems = (props) => (
  <nav>
    <ul className="FooterNavItems">
      <FooterNavItem link="/about">このサイトについて</FooterNavItem>
      <FooterNavItem link="/contact">お問い合わせ</FooterNavItem>
    </ul>
    <p className="FooterNavItems__copyright">
      &copy; by Hiroki Suzuki. All rights reserved.
    </p>
  </nav>
);

export default footerNavItems;
