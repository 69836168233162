import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withAlert } from 'react-alert';

import Auxiliary from './../../../hoc/Auxiliary/Auxiliary';
import Button from './../../UI/Button/Button';

import classes from './DeletePlaceForm.module.scss';

import * as actionCreators from './../../../store/actions/index';

class DeletePlaceForm extends Component {
  bookmarkSubmitHandler = async (placeId, userId, alert) => {
    // dispatch
    await this.props.onDeleteBookmarkPlace(placeId, userId);

    this.props.closed();

    if (this.props.bookmarkStatus.delete === true) {
      alert.success('「ブックマークしたお気に入りの場所」から削除しました');
    }

    if (this.props.bookmarkStatus.delete === false) {
      alert.error(
        '「ブックマークしたお気に入りの場所」からの削除に失敗しました'
      );
    }

    // reset
    this.props.onResetDeleteBookmark();
  };

  selfSubmitHandler = async (placeId, alert) => {
    // dispatch
    await this.props.onDeleteSelfPlace(placeId);

    this.props.closed();

    if (this.props.placeDataStatus.delete === true) {
      alert.success('お気に入りの場所を削除することに成功しました');
    }

    if (this.props.placeDataStatus.delete === false) {
      alert.error('お気に入りの場所を削除することに失敗しました');
    }

    // reset
    this.props.onResetDeleteSelfPlace();

    // カテゴリにある自分のお気に入りの場所があれば削除
    this.props.onDeleteCategorySelfPlace(placeId);
  };

  categorySubmitHandler = async (placeId, alert) => {
    try {
      await this.props.onDeleteCategoryPlace(
        placeId,
        this.props.selectedCategory._id
      );

      this.props.closed();

      alert.success('カテゴリを削除することに成功しました');
    } catch (error) {
      this.props.closed();

      alert.error('カテゴリを削除することに失敗しました');
    }
  };

  submitHandler = () => {
    const alert = this.props.alert;

    const placeId = this.props.id;
    const userId = this.props.user.id;

    // bookmark or self or category
    if (this.props.type === 'self') {
      this.selfSubmitHandler(placeId, alert);
    }
    if (this.props.type === 'bookmark') {
      this.bookmarkSubmitHandler(placeId, userId, alert);
    }
    if (this.props.type === 'category') {
      this.categorySubmitHandler(placeId, alert);
    }
  };

  render() {
    // フォームの有無
    let formClasses = [classes.delete_place_window, classes.hidden];
    let overlayClasses = [classes.overlay, classes.hidden];

    if (this.props.open) {
      formClasses = [classes.delete_place_window];
      overlayClasses = [classes.overlay];
    }

    return (
      <Auxiliary>
        <div
          className={overlayClasses.join(' ')}
          onClick={this.props.closed}
        ></div>
        <div className={formClasses.join(' ')}>
          <button
            className={classes.btn__close_modal}
            onClick={this.props.closed}
          >
            x
          </button>
          <div className={classes.delete}>
            <h3 className={classes.delete__heading}>
              お気に入りの場所を削除してもよろしいですか？
            </h3>
            <div className={classes.btn_box}>
              <Button
                btnType="danger"
                styleType="flex_btn"
                clicked={() => this.submitHandler()}
              >
                削除
              </Button>
              <Button btnType="cencel" clicked={this.props.closed}>
                キャンセル
              </Button>
            </div>
          </div>
        </div>
      </Auxiliary>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    bookmarkStatus: state.auth.placeDataMsg,
    placeDataStatus: state.place.placeDataMsg,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onDeleteBookmarkPlace: (placeId, userId) =>
      dispatch(actionCreators.deleteBookmarkPlace(placeId, userId)),
    onResetDeleteBookmark: () => dispatch(actionCreators.resetDeleteBookmark()),
    onDeleteSelfPlace: (placeId) =>
      dispatch(actionCreators.deleteSelfPlace(placeId)),
    onResetDeleteSelfPlace: () =>
      dispatch(actionCreators.resetDeleteSelfPlace()),
    onDeleteCategoryPlace: (placeId, categoryId) =>
      dispatch(actionCreators.deleteCategoryPlace(placeId, categoryId)),
    onDeleteCategorySelfPlace: (placeId) =>
      dispatch(actionCreators.deleteCategorySelfPlace(placeId)),
  };
};

DeletePlaceForm = compose(
  withAlert(),
  connect(mapStateToProps, mapDispatchToProps)
)(DeletePlaceForm);

export default DeletePlaceForm;
