import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withAlert } from 'react-alert';

import CheckIcon from '@material-ui/icons/Check';

import classes from './CategoryDropdown.module.scss';

import * as actionCreators from './../../../../store/actions/index';

class CategoryDropdown extends Component {
  categoryClickHandler = async (e) => {
    const alert = this.props.alert;

    // categoryIdの取得
    const categoryId = e.currentTarget.dataset.categoryId;
    // placeIdの取得
    const placeId = this.props.selectedPlace;
    // 子要素の取得
    // 1: そのカテゴリにお気に入りの場所が存在する
    // 2: そのカテゴリにお気に入りの場所が存在しない
    const childEl = e.currentTarget.childElementCount;

    try {
      if (childEl === 1) {
        await this.props.onEdtCategoryPlace(placeId, categoryId);

        alert.success('カテゴリに追加することに成功しました');
      }
      if (childEl === 2) {
        await this.props.onDeleteCategoryPlace(placeId, categoryId);

        alert.success('カテゴリから削除することに成功しました');
      }
    } catch (error) {
      if (childEl === 1) {
        alert.error('カテゴリに追加することに失敗しました');
      }
      if (childEl === 2) {
        alert.error('カテゴリから削除することに失敗しました');
      }
    }
  };
  render() {
    let boxClasses = [classes.dropdown_menu, classes.hidden];

    if (this.props.open) {
      boxClasses = [classes.dropdown_menu];
    }
    return (
      <ul className={boxClasses.join(' ')}>
        <li className={classes.dropdown_menu_header}>カテゴリ</li>
        <li className={classes.dropdown_menu_divider}></li>
        <ul className={classes.dropdown_menu_category}>
          {this.props.places
            ? this.props.places.map((p) => (
                <li key={p._id}>
                  <div
                    className={classes.dropdown_menu_list}
                    onClick={this.categoryClickHandler}
                    data-category-id={p._id}
                  >
                    <span>{p.name}</span>
                    {p.place_id.map((categoryPlace, i) =>
                      categoryPlace._id === this.props.selectedPlace ? (
                        <CheckIcon
                          classes={{ root: classes.dropdown_menu_icon }}
                          key={i}
                        />
                      ) : (
                        ''
                      )
                    )}
                  </div>
                </li>
              ))
            : ''}
        </ul>
      </ul>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onEdtCategoryPlace: (placeId, categoryId) =>
      dispatch(actionCreators.edtCategoryPlace(placeId, categoryId)),
    onDeleteCategoryPlace: (placeId, categoryId) =>
      dispatch(actionCreators.deleteCategoryPlace(placeId, categoryId)),
  };
};

CategoryDropdown = compose(
  withAlert(),
  connect(null, mapDispatchToProps)
)(CategoryDropdown);

export default CategoryDropdown;
