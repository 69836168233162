import React, { Component } from "react";
import { compose } from "redux";
import { withAlert } from "react-alert";
import MultiRef from "react-multi-ref";

import Input from "../../components/UI/Input/Input";
import Button from "../../components/UI/Button/Button";

import { inputChangedHandlerFunc } from "./../../utility/InputChangeHandler/InputChangeHandler";
import { contactMail } from "./../../utility/Share/utilFunc";
import updateObject from "./../../utility/Share/updateObject";

import classes from "./Contact.module.scss";

class Contact extends Component {
  state = {
    controls: {
      name: {
        elementType: "input",
        label: "お名前",
        elementConfig: {
          type: "text",
          placeholder: "お名前",
          id: "name",
        },
        value: "",
        validation: {
          required: true,
        },
      },
      email: {
        elementType: "input",
        label: "メールアドレス",
        elementConfig: {
          type: "email",
          placeholder: "you@example.com",
          id: "email",
        },
        value: "",
        validation: {
          required: true,
        },
      },
      contact: {
        elementType: "textarea",
        label: "お問い合わせ内容",
        elementConfig: {
          placeholder: "お問い合わせ内容",
          id: "contact",
          rows: "5",
          cols: "33",
        },
        value: "",
        validation: {
          required: true,
        },
      },
    },
  };
  _items = new MultiRef();

  inputChangedHandler = (event, controlName) => {
    this.setState({
      controls: inputChangedHandlerFunc(this.state, event, controlName),
    });
  };

  submitHandler = async (event) => {
    event.preventDefault();

    const alert = this.props.alert;

    const data = {};
    Object.keys(this.state.controls).forEach(
      (key) => (data[key] = this.state.controls[key].value)
    );

    try {
      const status = await contactMail(data);

      if (status === true) {
        alert.success("お問い合わせの送信に成功しました");
      }
    } catch (error) {
      alert.error("お問い合わせの送信に失敗しました");
    }

    // input textarea value 削除
    Object.keys(this.state.controls).forEach((key) => {
      this.setState({
        controls: updateObject(this.state.controls, {
          [key]: updateObject(this.state.controls[key], {
            value: "",
          }),
        }),
      });
    });
    this._items.map.forEach((item) => (item.value = ""));
  };

  render() {
    const formElArray = [];
    for (const key in this.state.controls) {
      formElArray.push({
        id: key,
        config: this.state.controls[key],
      });
    }
    let form = formElArray.map((el) => (
      <Input
        key={el.id}
        elementType={el.config.elementType}
        label={el.config.label}
        htmlFor={el.config.elementConfig.id}
        elementConfig={el.config.elementConfig}
        value={el.config.value}
        required={el.config.validation.required}
        changed={(event) => this.inputChangedHandler(event, el.id)}
        refProp={this._items.ref(el.id)}
      />
    ));

    return (
      <main className={classes.main}>
        <h2 className={classes.contact__title}>お問い合わせ</h2>
        <p className={classes.contact__description}>
          何かご不明点などございましたら、こちらのフォームよりお問い合わせください。
        </p>
        <p className={classes.contact__description}>
          メールにてサイト運営者よりご連絡差し上げます。
        </p>
        <div className={classes.contact_form}>
          <form onSubmit={this.submitHandler}>
            {form}
            <Button btnType="success">送信</Button>
          </form>
        </div>
      </main>
    );
  }
}

Contact = compose(withAlert())(Contact);

export default Contact;
