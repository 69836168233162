import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import EditIcon from '@material-ui/icons/Edit';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Auxiliary from './../../../hoc/Auxiliary/Auxiliary';
import CategoryDropdown from './../../UI/Dropdown/CategoryDropdown/CategoryDropdown';

import { convertToJapTime } from './../../../utility/Share/utilFunc';
import { config } from './../../../config';

import classes from './SettingCard.module.scss';

class SettingCard extends Component {
  render() {
    return (
      <div className={`${classes.hotel__card} ${classes.linkbox}`}>
        <NavLink to={`/place/${this.props.place.id}`} />
        <div className={classes.card__header}>
          <div className={classes.hotel__card__picture}>
            <img
              src={`${config.s3.URL}/places/${this.props.place.images[0]}`}
              alt={this.props.place.name}
              className={classes.card__picture_img}
            />
          </div>
        </div>
        <div className={`${classes.hotel__card_details} ${classes.p_05}`}>
          <div className={classes.icon_wrapper}>
            {this.props.bookmark ? (
              <Auxiliary>
                <div
                  onClick={this.props.deleteClick}
                  data-delete-type={
                    this.props.category ? 'category' : 'bookmark'
                  }
                  style={{ display: 'inline' }}
                  data-place-id={this.props.place.id}
                  data-category-index={this.props.categoryIndex}
                >
                  <DeleteOutlineIcon className={classes.trash_icon} />
                </div>
                <div
                  style={{ display: 'inline' }}
                  onClick={this.props.categoryBoxClick}
                  data-place-index={this.props.index}
                  data-bookmark-flg={true}
                  data-category-index={this.props.categoryIndex}
                >
                  <MoreVertIcon className={classes.trash_icon} />
                </div>
                <CategoryDropdown
                  open={
                    this.props.categoryBookmarkOpen ||
                    this.props.categoryPlaceOpen
                  }
                  places={this.props.categoryPlaces}
                  selectedPlace={this.props.place.id}
                />
              </Auxiliary>
            ) : (
              <Auxiliary>
                <div
                  onClick={this.props.addClick}
                  data-place-id={this.props.place.id}
                  style={{ display: 'inline' }}
                >
                  <EditIcon className={classes.edit_icon} />
                </div>
                <div
                  onClick={this.props.deleteClick}
                  data-delete-type="self"
                  style={{ display: 'inline' }}
                  data-place-id={this.props.place.id}
                >
                  <DeleteOutlineIcon className={classes.trash_icon} />
                </div>
                <div
                  style={{ display: 'inline' }}
                  onClick={this.props.categoryBoxClick}
                  data-place-index={this.props.index}
                  data-bookmark-flg={false}
                >
                  <MoreVertIcon className={classes.trash_icon} />
                </div>
                <CategoryDropdown
                  open={this.props.categorySelfOpen}
                  places={this.props.categoryPlaces}
                  selectedPlace={this.props.place.id}
                />
              </Auxiliary>
            )}
          </div>
          <h3 className={classes.card__sub_heading}>{this.props.place.name}</h3>
          <div className={classes.card__data}>
            <FavoriteBorderIcon className={classes.icon_style} />
            <span className={`${classes.mr_10} ${classes.ml_05}`}>
              {this.props.place.likes.length}
            </span>
            <ChatBubbleOutlineIcon className={classes.icon_style} />
            <span className={classes.ml_05}>
              {this.props.place.comments.length}
            </span>
          </div>
          <div className={classes.card__data_grid}>
            {this.props.place.genre ? (
              <span className={classes.btn_genre}>
                #{this.props.place.genre}
              </span>
            ) : (
              ''
            )}
          </div>
          <div className={classes.card__data}>
            <PersonOutlineIcon className={classes.icon_style} />
            <span className={`${classes.mr_10} ${classes.ml_05}`}>
              {this.props.place.user.name}
            </span>
            <CalendarTodayIcon className={classes.icon_style} />
            <span className={classes.ml_05}>
              {convertToJapTime(this.props.place.id)}
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default SettingCard;
